import { toTwoDecimalPlaces } from '../utils/FloatUtils';
import { convertNumberToWordsWithDecimal } from '../utils/formatNumber';

const renderItemRow = (type, item, index) => {
  const invoiceId = type === 'RECEIPT' ? item.invoiceId : item.orderId;
  return `<tr>
                <td colspan="1">${index}</td>
                <td colspan="2">${invoiceId}</td>
                <td colspan="2">${item.total}</td>
                <td colspan="2">${item.balance}</td>
                <td colspan="1"></td>
            </tr>`;
};

const renderNotesRow = (receiptDetails) =>
  receiptDetails.notes
    ? `<tr>
                <td class="info"><strong>Notes:</strong></td>
            </tr>
            <tr>
                <td class="info">${receiptDetails.notes}</td>
            </tr>`
    : '';

export const receiptPreviewHtmlTemplate = (
  type,
  receiptType,
  companyDetails,
  customerDetails,
  receiptDetails,
  billingSettings
) => {
  let receiptTitle = '';
  switch (receiptType) {
    case 1:
      receiptTitle = 'ORIGINAL RECEIPT';
      break;
    case 2:
      receiptTitle = 'DUPLICATE COPY';
      break;
    case 3:
      receiptTitle = 'TRIPLICATE COPY';
      break;
    case 4:
      receiptTitle = 'EXTRA COPY';
      break;
    default:
      receiptTitle = 'ORIGINAL RECEIPT';
  }

  const currencySymbol = billingSettings.currencySymbol.label;
  const isReceipt = type === 'RECEIPT';
  const receiptIdTitle = isReceipt ? 'Receipt Id' : 'Payment Id';
  const idTitle = isReceipt ? 'Invoice Id' : 'Order Id';
  const receiptId = isReceipt ? receiptDetails.receiptId : receiptDetails.paymentId;
  const receiptItems = isReceipt ? receiptDetails.invoices : receiptDetails.purchaseOrders;
  const totalItems = receiptItems.length;
  const items = receiptItems
    .map((item, index) => renderItemRow(type, item, index + 1))
    .reduce((a, b) => `${a}\n${b}`, '');
  const amount = toTwoDecimalPlaces(receiptDetails.amount);

  const termsAndConditions = billingSettings.receiptPaymentTerms
    .toString()
    .split('\n')
    .map((term) => `<p>${term}</p>`)
    .reduce((a, b) => `${a}\n${b}`, '');

  const amountInWords = (amount) =>
    `<p class="bold">Rupees ${convertNumberToWordsWithDecimal(amount)} only</p>`;

  const notesRow = renderNotesRow(receiptDetails);
  const base64Prefix = `data:image/jpeg;base64,`;
  // eslint-disable-next-line no-nested-ternary
  const companyLogo = companyDetails.photoURL
    ? companyDetails.photoURL.preview
      ? base64Prefix + companyDetails.photoURL.preview
      : null
    : null;

  return `
    <!DOCTYPE html>
    <html lang="en" dir="ltr">
        <head>
            <meta charset="utf-8">
            <title>Receipt Preview</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
                  integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous"/>
            <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
                    integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
                    crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"
                    integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN"
                    crossorigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"
                    integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV"
                    crossorigin="anonymous"></script>
                    
            <style>
                @media print { * { overflow: hidden!important; } }
                body {
                    margin: auto;
                }
        
                hr {
                    margin: 5px;
                }
                
                p {
                    margin-bottom: 5px;
                }
                
                .bold {
                    font-weight: bold;
                }
                
                .logo {
                    width: 75px;
                    height: 75px;
                    padding-left: 500px;
                    border-left: 400px;
                }
                
                .table, th, td {
                    /*border: 1px solid black;*/
                    border-collapse: collapse;
                }
                
                .heading {
                    font-size: 16px;
                    font-weight: bold;
                }
                
                .first {
                    margin: 40px 2% 2%;
                }
                
                .second {
                    margin: 0 2%;
                }
                
                .third {
                    margin: 0 2%;
                }
                
                .sub {
                    font-size: 20px;
                }
                
                .fourth {
                    margin: 0 2%;
                }
                
                .last {
                    margin: 0 2%;
                    border-bottom: 0;
                }
                
                table {
                    empty-cells: show;
                }
            </style>
        </head>
                <body>
            <!--first-->
            <div class="first">
                <div class="row">
                    <div class="col-12 text-center">
                        <h5><strong>${receiptTitle}</strong></h5>
                    </div>
                </div>
            </div>
            <hr>
            
            <!--first-->
            <div class="first">
                <div class="row">
                    <!-- Company Logo -->
                    <div class="col-2">
                        <img height="200px"
                             width="200px"
                             src="${companyLogo}"
                             alt="${companyDetails.firmName}">
                    </div>
                    
                    <!-- Company Details -->
                    <div class="col-5" style="margin-top: 3%">
                        <h6 class="heading">${companyDetails.firmName}</h6>
                        <p>${companyDetails.firmAddress}</p>
                        <p>${companyDetails.phoneNumber}</p>
                        <p>${companyDetails.gstNumber}</p>
                    </div>
                    
                    <!-- Customer Details -->
                    <div class="col-5" style="margin-top: 3%">
                        <h6 class="heading">
                            <u>${isReceipt ? 'Customer' : 'Supplier'} Details:</u>
                        </h6>
                        <h6 class="heading">${customerDetails.name}</h6>
                        <p>${customerDetails.shippingAddress}</p>
                        <p>${
                          customerDetails.id !== '-1'
                            ? customerDetails.phoneNumber
                            : 'Not Available'
                        }</p>
                        <p>${
                          customerDetails.gstNumber ? customerDetails.gstNumber : 'Not Available'
                        }</p>
                    </div>
                </div>
            </div>
            <hr>
    
            <div id="invoice-details" class="row">
                <table class="table table-striped">
                    <tr>
                        <th colspan="3">${receiptIdTitle}: ${receiptId}</th>
                        <th colspan="3">Date: ${receiptDetails.createdAt}</th>
                    </tr>
                </table>
            </div>
        
            <div id="item-summary" class="row">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th colspan="1">S.No</th>
                        <th colspan="2">${idTitle}</th>
                        <th colspan="2">Inv Amount (${currencySymbol})</th>
                        <th colspan="2">Balance (${currencySymbol})</th>
                        <th colspan="1"></th>
                    </tr>
                    </thead>
                    <tbody>
                        ${items}
                    </tbody>
                </table>
            </div>
        
            <div id="total-summary" class="row">
                <table class="table table-bordered">
                    <!-- <tr>
                        <td class="info" style="width:50%">Total items</td>
                        <td class="info" style="width:50%">${totalItems}</td>
                    </tr> -->
                    <tr>
                        <td class="info" style="width:50%">Total Amount</td>
                        <td class="info" style="width:50%">${currencySymbol} ${amount}</td>
                    </tr>
                </table>
            </div>
        
            <div id="payment-summary" class="row">
                <p class="info" style="font-weight: bold; text-align: left;">Payment Summary</p>
                <table class="table table-striped" style="width: 100%">
                    <tr>
                        <td class="info" style="width: 50%">Payment Type</td>
                        <td class="info" style="width:50%">${receiptDetails.paymentType.value}</td>
                    </tr>
                    ${notesRow}
                </table>
            </div>
        
            <!--five-->
            <div class="row">
                <table class="table table-bordered" style="width:100%">
                    <tr>
                        <th style="width:50%">Terms & Conditions:</th>
                        <th style="width:50%">Amount in Words</th>
                    </tr>
                    <tr>
                        <td style="width:50%">${termsAndConditions}</td>
                        <td style="width:50%">${amountInWords(amount)}</td>
                    </tr>
                </table>
            </div>
        
            <!--last-->
            <div class="first">
                <div class="row">
                    <div class="col-12 text-center">
                        <h5><strong>${billingSettings.thankYouNotes}</strong></h5>
                    </div>
                </div>
            </div>
        
        </body>
    </html>
`;
};
