import {
  FETCH_INVOICES,
  ADD_INVOICE,
  UPDATE_INVOICE,
  REFRESH_INVOICES
} from '../actions/invoicesActions';
import Invoice from '../../models/Invoice';
import { formatDateWithCurrentTime, sortItemsByDate } from '../../utils/DateUtils';

const initialState = {
  invoices: [],
  currentInvoiceId: 1,
  prefix: 'INV-'
};

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return {
        ...state,
        invoices: sortItemsByDate(state.invoices)
      };
    case REFRESH_INVOICES:
      return state;
    case ADD_INVOICE: {
      const { data } = action;
      // console.log(`Data In reducer: ${JSON.stringify(data)}`);
      const { currentInvoiceId } = state;
      const invoice = new Invoice(
        currentInvoiceId.toLocaleString(),
        data.invoiceId,
        data.customerId,
        data.items,
        data.subTotal,
        data.taxTotal,
        data.itemDiscountTotal,
        data.overallDiscountTotal,
        data.shippingCharges,
        data.roundOff,
        data.total,
        data.total,
        data.paymentType,
        formatDateWithCurrentTime(data.createdAt),
        formatDateWithCurrentTime(data.createdAt),
        data.paymentStatus,
        data.personThrough
      );
      let updatedInvoices = [...state.invoices];
      updatedInvoices = updatedInvoices.concat(invoice);
      return {
        ...state,
        currentInvoiceId: currentInvoiceId + 1,
        invoices: sortItemsByDate(updatedInvoices)
      };
    }
    case UPDATE_INVOICE: {
      const invoiceData = action.data;
      const updatedInvoice = new Invoice(
        invoiceData.id,
        invoiceData.invoiceId,
        invoiceData.customerId,
        invoiceData.items,
        invoiceData.subTotal,
        invoiceData.taxTotal,
        invoiceData.itemDiscountTotal,
        invoiceData.overallDiscountTotal,
        invoiceData.shippingCharges,
        invoiceData.roundOff,
        invoiceData.total,
        invoiceData.balance,
        invoiceData.paymentType,
        invoiceData.createdAt,
        formatDateWithCurrentTime(new Date()),
        invoiceData.paymentStatus,
        invoiceData.personThrough
      );
      let finalUpdatedInvoices = [...state.invoices];
      finalUpdatedInvoices = finalUpdatedInvoices.filter((item) => item.id !== updatedInvoice.id);
      finalUpdatedInvoices = finalUpdatedInvoices.concat(updatedInvoice);
      return {
        ...state,
        invoices: sortItemsByDate(finalUpdatedInvoices)
      };
    }
    default:
      return state;
  }
};

export default invoicesReducer;
