import { UPDATE_PURCHASE_ORDER } from './purchaseActions';
import { sortItemsByDate } from '../../utils/DateUtils';
import { toTwoDecimalPlaces } from '../../utils/FloatUtils';
import { PAYMENT_STATUSES } from '../../config/data/init';

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const REFRESH_PAYMENTS = 'REFRESH_PAYMENTS';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const addPayment = (data) => (dispatch, getState) => {
  dispatch({ type: ADD_PAYMENT, data });

  const { purchaseOrderIds } = data;
  const purchaseOrders = sortItemsByDate(
    getState().purchaseOrders.purchaseOrders.filter((pOrder) =>
      purchaseOrderIds.includes(pOrder.id)
    )
  );

  // const purchaseOrders = sortItemsByDate(data.purchaseOrderIds);
  let updatedTotal = parseFloat(data.amount);
  let due = 0.0;
  let paymentStatus = PAYMENT_STATUSES.DUE;
  purchaseOrders.forEach((pOrder) => {
    updatedTotal = updatedTotal < 0 ? 0 : updatedTotal;
    due = parseFloat(updatedTotal) - parseFloat(pOrder.balance);
    updatedTotal = due;
    due = due < 0 ? -due : 0;
    paymentStatus = due === 0 ? PAYMENT_STATUSES.PAID : PAYMENT_STATUSES.DUE;
    dispatch({
      type: UPDATE_PURCHASE_ORDER,
      data: { ...pOrder, balance: toTwoDecimalPlaces(due), paymentStatus }
    });
  });
};

export const updatePayment = (data) => ({ type: UPDATE_PAYMENT, data });
