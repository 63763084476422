import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personDoneFill from '@iconify/icons-eva/person-done-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import priceTagsFill from '@iconify/icons-eva/pricetags-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import creditCardFill from '@iconify/icons-eva/credit-card-fill';
import attachFill from '@iconify/icons-eva/attach-fill';
import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import percentFill from '@iconify/icons-eva/percent-fill';
import options2Fill from '@iconify/icons-eva/options-2-fill';
import logoutFill from '@iconify/icons-ant-design/logout';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'invoice',
    path: '/dashboard/invoice',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'purchase Order',
    path: '/dashboard/purchaseOrder',
    icon: getIcon(priceTagsFill)
  },
  {
    title: 'receipt',
    path: '/dashboard/receipt',
    icon: getIcon(attachFill)
  },
  {
    title: 'payment',
    path: '/dashboard/payment',
    icon: getIcon(creditCardFill)
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon(clockFill),
    children: [
      {
        title: 'customer',
        path: '/dashboard/reports/customer',
        icon: getIcon(peopleFill)
      },
      {
        title: 'supplier',
        path: '/dashboard/reports/supplier',
        icon: getIcon(personDoneFill)
      },
      {
        title: 'sales',
        path: '/dashboard/reports/sales',
        icon: getIcon(fileTextFill)
      },
      {
        title: 'purchase',
        path: '/dashboard/reports/purchase',
        icon: getIcon(priceTagsFill)
      },
      {
        title: 'stock',
        path: '/dashboard/reports/stock',
        icon: getIcon(shoppingBagFill)
      }
    ]
  },
  {
    title: 'customer',
    path: '/dashboard/customer',
    icon: getIcon(peopleFill)
  },
  {
    title: 'supplier',
    path: '/dashboard/supplier',
    icon: getIcon(personDoneFill)
  },
  {
    title: 'stock',
    path: '/dashboard/stock',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: getIcon(options2Fill),
    children: [
      {
        title: 'bank Account',
        path: '/dashboard/settings/bankAccount',
        icon: getIcon(briefcaseFill)
      },
      {
        title: 'tax Rate',
        path: '/dashboard/settings/taxRate',
        icon: getIcon(percentFill)
      },
      {
        title: 'measurement Unit',
        path: '/dashboard/settings/unit',
        icon: getIcon(options2Fill)
      },
      {
        title: 'Import Stock',
        path: '/dashboard/settings/import/stock',
        icon: getIcon(shoppingBagFill)
      }
    ]
  },
  {
    title: 'logout',
    path: '/logout',
    icon: getIcon(logoutFill)
  }
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
