import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Button, Container, Stack, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import cloudDownload from '@iconify/icons-eva/cloud-download-fill';
import printerOutline from '@iconify/icons-eva/printer-outline';
import { useReactToPrint } from 'react-to-print';
import { invoicePreviewHtmlTemplate } from '../models/InvoiceTemplate';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

const PrintPreview = (props) => {
  const { id } = useParams();
  const { type } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const isInvoice = type === 'INVOICE';

  const { settings } = useSelector((state) => state.settings);

  const { customers } = useSelector((state) => state.customers);
  const { invoices } = useSelector((state) => state.invoices);

  const { suppliers } = useSelector((state) => state.suppliers);
  const { purchaseOrders } = useSelector((state) => state.purchaseOrders);

  const getUserDetails = (invoiceDetails) =>
    isInvoice
      ? getCustomerDetails(invoiceDetails.customerId)
      : getSupplierDetails(invoiceDetails.supplierId);

  const getCustomerDetails = (id) =>
    customers.find((customer) => customer.id.toString() === id.toString());

  const getSupplierDetails = (id) =>
    suppliers.find((supplier) => supplier.id.toString() === id.toString());

  const getInvoiceDetails = (id) =>
    isInvoice
      ? invoices.find((invoice) => invoice.id.toString() === id.toString())
      : purchaseOrders.find((order) => order.id.toString() === id.toString());

  const invoiceDetails = getInvoiceDetails(id);

  const firmDetails = settings.user.companyProfile;

  const printData = {
    type,
    firmDetails,
    customerDetails: getUserDetails(invoiceDetails),
    invoiceDetails,
    billingSettings: settings.billing,
    printSettings: settings.printing,
    taxSettings: settings.taxSlabs,
    bankSettings: settings.bankAccounts
  };

  const printPreview = invoicePreviewHtmlTemplate(
    printData.type,
    1,
    printData.firmDetails,
    printData.customerDetails,
    printData.invoiceDetails,
    printData.billingSettings,
    printData.bankSettings
  );

  const printInvoice = () => {
    const a = window.open(' ');
    a.document.write(printPreview);
    a.document.close();
    a.print();
  };

  return (
    <Page title="Dashboard: Print Preview | Quick Bill">
      <Scrollbar>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack width="60%" alignItems="left">
              <Typography variant="h4" gutterBottom>
                {isInvoice ? 'Invoice ' : 'Purchase Order '} Preview
              </Typography>
            </Stack>
            <Stack direction="row" width="40%" alignItems="right" justifyContent="space-around">
              <Button
                variant="contained"
                onClick={handlePrint}
                startIcon={<Icon icon={cloudDownload} />}
              >
                Print {isInvoice ? 'Invoice' : 'Purchase Order'} (PDF)
              </Button>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            margin={5}
            ref={componentRef}
          >
            {parse(printPreview)}
          </Stack>
        </Container>
      </Scrollbar>
    </Page>
  );
};

export default PrintPreview;
