// material
import { Autocomplete, Container, Divider, Stack, TextField, Typography } from '@material-ui/core';
// components
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as receiptActions from '../store/actions/receiptsActions';
import * as paymentActions from '../store/actions/paymentsActions';
import Page from '../components/Page';
import Customer from '../models/Customer';
import Supplier from '../models/Supplier';
import Scrollbar from '../components/Scrollbar';
import {
  DEFAULT_CUSTOMER_NONE,
  DEFUALT_BANK_ACCOUNT_NONE,
  DEFUALT_PAYMENT_TYPE_NONE,
  PAYMENT_STATUSES,
  PAYMENT_TYPES
} from '../config/data/init';
import BankAccount from '../models/BankAccount';
import Invoice from '../models/Invoice';
//
// ----------------------------------------------------------------------

export default function AddReceiptPayment(props) {
  const { id } = useParams();
  const { type, userType } = props;
  const [receiptPaymentObj, setReceiptPaymentObj] = useState({
    user: '',
    receiptId: '',
    receiptDate: '',
    items: [],
    paymentType: '', // PAYMENT_TYPES.NONE,
    bankAccount: '', // new BankAccount('0', 'None', 'None', 'None', 'None', 'None'),
    notes: '',
    amount: '',
    invoice: ''
  });
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [userInvoices, setUserInvoices] = useState({});
  const [bankAccounts, setBankAccounts] = useState({});
  const [invoiceTotals, setInvoiceTotals] = useState({});
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();
  const { suppliers } = useSelector((state) => state.suppliers);
  const { customers } = useSelector((state) => state.customers);
  const { invoices } = useSelector((state) => state.invoices);
  const { receipts } = useSelector((state) => state.receipts);
  const { purchaseOrders } = useSelector((state) => state.purchaseOrders);
  const { payments } = useSelector((state) => state.payments);
  const { items } = useSelector((state) => state.stock.stock);
  // const { bankDetails } = useSelector((state) => state.settings.settings.user.companyProfile);
  const bankDetails = useSelector((state) => state.settings.settings.bankAccounts);

  useEffect(() => {
    setTitle(renderTitle(type));
    const bankAcc = {
      None: new BankAccount('0', 'None', 'None', 'None', 'None', 'None')
    };
    bankDetails.forEach((bankDetail) => {
      bankAcc[bankDetail.accountNumber] = bankDetail;
    });
    setBankAccounts(bankAcc);
  }, [type]);

  const populateUserDetails = () => {
    let receiptPayment = {};
    let user = {};
    if (userType === 'RECEIPT') {
      receiptPayment = receipts.find((receipt) => receipt.id.toString() === id.toString());
      user = customers.find(
        (customer) => customer.id.toString() === receiptPayment.customerId.toString()
      );
    } else {
      receiptPayment = payments.find((payment) => payment.id.toString() === id.toString());
      user = suppliers.find(
        (supplier) => supplier.id.toString() === receiptPayment.supplierId.toString()
      );
    }
    setReceiptPaymentObj({
      user,
      receiptId: receiptPayment.receiptId || receiptPayment.paymentId,
      receiptDate: receiptPayment.createdAt,
      items: receiptPayment.items,
      paymentType: receiptPayment.paymentType,
      bankAccount: receiptPayment.bankAccount,
      notes: receiptPayment.notes
    });
  };

  const renderTitle = (type) => {
    let title = '';
    const user = userType === 'RECEIPT' ? 'Receipt' : 'Payment';
    switch (type) {
      case 'ADD':
        title = `Add ${user}`;
        break;
      case 'EDIT':
        title = `Edit ${user}`;
        populateUserDetails();
        break;
      default:
        title = `${user} Details`;
        break;
    }
    return title;
  };
  const navigate = useNavigate();

  const ReceiptPaymentSchema = Yup.object().shape({
    user: Yup.object().required('Customer/Supplier required').nullable(),
    receiptId: Yup.string().required('Receipt/Payment Id required'),
    receiptDate: Yup.date().required('Receipt/Payment Date required'),
    paymentType: Yup.object().required('Payment Type required').nullable(),
    bankAccount: Yup.object().required('Bank Account Required').nullable(),
    amount: Yup.string().required('Amount Required'),
    invoice: Yup.object().required('Invoice/Order Id Required')
  });

  const formik = useFormik({
    initialValues: receiptPaymentObj,
    enableReinitialize: true,
    validationSchema: ReceiptPaymentSchema,
    onSubmit: () => {
      const updatedReceiptPaymentObj = {
        createdAt: getFieldProps('receiptDate').value,
        updatedAt: getFieldProps('receiptDate').value,
        paymentType: getFieldProps('paymentType').value, // PAYMENT_TYPES.NONE,
        bankAccount: getFieldProps('bankAccount').value, // new BankAccount('0', 'None', 'None', 'None', 'None', 'None'),
        notes: getFieldProps('notes').value,
        amount: getFieldProps('amount').value
      };
      // console.log('inside submit');
      // console.log(`ReceiptPaymentObj: ${JSON.stringify(updatedReceiptPaymentObj)}`);
      if (type === 'ADD') {
        const invoice = getFieldProps('invoice').value;
        if (userType === 'RECEIPT') {
          updatedReceiptPaymentObj.customerId = getFieldProps('user').value.id;
          updatedReceiptPaymentObj.receiptId = getFieldProps('receiptId').value;
          updatedReceiptPaymentObj.invoiceIds = invoice ? [invoice] : [];
          dispatch(receiptActions.addReceipt(updatedReceiptPaymentObj));
        } else {
          updatedReceiptPaymentObj.supplierId = getFieldProps('user').value.id;
          updatedReceiptPaymentObj.paymentId = getFieldProps('receiptId').value;
          updatedReceiptPaymentObj.purchaseOrderIds = invoice ? [invoice] : [];
          dispatch(paymentActions.addPayment(updatedReceiptPaymentObj));
        }
      } else if (type === 'EDIT') {
        // eslint-disable-next-line no-empty
        if (userType === 'INVOICE') {
          // eslint-disable-next-line no-empty
        } else {
        }
      }
      if (type === 'EDIT') {
        navigate('./../../', { replace: true });
      } else {
        navigate('./../', { replace: true });
      }
    }
  });

  const onUpdateHandler = (items, totals) => {
    setSelectedInvoiceItems(items);
    setInvoiceTotals(totals);
  };

  const loadUserInvoices = (user) => {
    // console.log({ ...user });
    let filteredInvoices = [];
    const userInvoices = {};
    if (userType === 'RECEIPT') {
      filteredInvoices = invoices.filter(
        (invoice) =>
          invoice.customerId === user.id &&
          invoice.paymentStatus.label.toString() === PAYMENT_STATUSES.DUE.label.toString()
      );
    } else {
      filteredInvoices = purchaseOrders.filter(
        (order) =>
          order.supplierId === user.id &&
          order.paymentStatus.label.toString() === PAYMENT_STATUSES.DUE.label.toString()
      );
    }
    // console.log({ ...filteredInvoices });
    filteredInvoices.forEach((invoice) => (userInvoices[invoice.id] = invoice));
    // console.log({ ...userInvoices });
    setUserInvoices(userInvoices);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;
  return (
    <Page title={`Dashboard: ${title} | Quick Bill`}>
      <Scrollbar>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          </Stack>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Autocomplete
                    defaultValue={DEFAULT_CUSTOMER_NONE}
                    options={userType === 'RECEIPT' ? customers : suppliers}
                    onChange={(event, value) => {
                      setFieldValue('user', value);
                      if (value && value !== '') {
                        loadUserInvoices(value);
                      }
                    }}
                    getOptionLabel={(option) => `${option.name} -- ${option.phoneNumber}`}
                    fullWidth
                    disabled={type !== 'ADD'}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Full Name"
                        {...params}
                        {...getFieldProps('user')}
                        error={Boolean(touched.user && errors.user)}
                        helperText={touched.user && errors.user}
                      />
                    )}
                  />

                  <TextField
                    fullWidth
                    label={`${userType === 'RECEIPT' ? 'Receipt' : 'Payment'} Id`}
                    {...getFieldProps('receiptId')}
                    error={Boolean(touched.receiptId && errors.receiptId)}
                    helperText={touched.receiptId && errors.receiptId}
                    disabled={type !== 'ADD'}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack width="50%">
                    <TextField
                      fullWidth
                      multiline
                      value={
                        getFieldProps('user').value !== undefined &&
                        getFieldProps('user').value !== null &&
                        getFieldProps('user').value !== ''
                          ? `${getFieldProps('user').value.name}\n${
                              getFieldProps('user').value.phoneNumber
                            }\n${getFieldProps('user').value.shippingAddress}\n${
                              getFieldProps('user').value.gstNumber
                            }`
                          : ``
                      }
                      label={`${userType === 'RECEIPT' ? 'Customer' : 'Supplier'} details`}
                      error={Boolean(touched.user && errors.user)}
                      helperText={touched.user && errors.user}
                      disabled
                    />
                  </Stack>
                  <Stack spacing={2} width="50%">
                    <TextField
                      fullWidth
                      {...getFieldProps('receiptDate')}
                      error={Boolean(touched.receiptDate && errors.receiptDate)}
                      helperText={touched.receiptDate && errors.receiptDate}
                      type="date"
                      disabled={type !== 'ADD'}
                    />
                    <Autocomplete
                      defaultValue={DEFUALT_PAYMENT_TYPE_NONE}
                      options={Object.values(PAYMENT_TYPES).filter(
                        (paymentType) => paymentType.label.toString() !== 'Credit'
                      )}
                      onChange={(event, value) => setFieldValue('paymentType', value)}
                      getOptionLabel={(option) => `${option.label}`}
                      fullWidth
                      disabled={type !== 'ADD'}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Payment Type"
                          {...params}
                          {...getFieldProps('paymentType')}
                          error={Boolean(touched.paymentType && errors.paymentType)}
                          helperText={touched.paymentType && errors.paymentType}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
                <Divider />
                {/* <InvoiceItems */}
                {/*  items={items} */}
                {/*  selectedItems={receiptPaymentObj.invoiceItems} */}
                {/*  type={id !== undefined ? 'EDIT' : ''} */}
                {/*  onUpdate={onUpdateHandler} */}
                {/* /> */}
                <Divider />
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack width="50%">
                    <Autocomplete
                      options={Object.values(userInvoices)}
                      onChange={(event, value) => {
                        setFieldValue('invoice', value);
                        setFieldValue('amount', value ? value.balance : '');
                      }}
                      getOptionLabel={(option) =>
                        userType === 'RECEIPT'
                          ? `Inv Id: ${option.invoiceId}`
                          : `Order Id: ${option.orderId}`
                      }
                      fullWidth
                      disabled={type !== 'ADD'}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label={userType === 'RECEIPT' ? 'Invoice' : 'Purchase Order'}
                          {...params}
                          {...getFieldProps('invoice')}
                          error={Boolean(touched.invoice && errors.invoice)}
                          helperText={touched.invoice && errors.invoice}
                        />
                      )}
                    />
                  </Stack>
                  <Stack width="50%">
                    <TextField
                      fullWidth
                      label="Amount"
                      {...getFieldProps('amount')}
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                      disabled={type !== 'ADD'}
                    />
                  </Stack>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack width="50%">
                    <TextField
                      fullWidth
                      label="Notes"
                      {...getFieldProps('notes')}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      disabled={type !== 'ADD'}
                    />
                  </Stack>
                  <Stack width="50%">
                    <Autocomplete
                      defaultValue={DEFUALT_BANK_ACCOUNT_NONE}
                      options={Object.values(bankAccounts)}
                      onChange={(event, value) => setFieldValue('bankAccount', value)}
                      getOptionLabel={(option) => `${option.bankName} - ${option.accountNumber}`}
                      fullWidth
                      disabled={type !== 'ADD'}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Bank Account"
                          {...params}
                          {...getFieldProps('bankAccount')}
                          error={Boolean(touched.bankAccount && errors.bankAccount)}
                          helperText={touched.bankAccount && errors.bankAccount}
                        />
                      )}
                    />
                  </Stack>
                </Stack>

                {(type === 'ADD' || type === 'EDIT') && (
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={type !== 'ADD'}
                  >
                    Save Details
                  </LoadingButton>
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Container>
      </Scrollbar>
    </Page>
  );
}
