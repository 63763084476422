import * as Yup from 'yup';
import { useCallback } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack5';
import { fData } from '../utils/formatNumber';
import { UploadAvatar } from '../components/upload';
import countries from '../config/data/countries';
import useIsMountedRef from '../hooks/useIsMountedRef';
import * as settingsActions from '../store/actions/settingsActions';

// ----------------------------------------------------------------------

export default function CompanyProfile() {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { companyProfile } = useSelector((state) => state.settings.settings.user);
  const user = companyProfile;

  const { enqueueSnackbar } = useSnackbar();
  const updateCompanyProfile = (data) => dispatch(settingsActions.updateCompanyProfile(data));

  const UpdateUserSchema = Yup.object().shape({
    firmName: Yup.string().required('Name is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firmName: user.firmName,
      firmEmailId: user.firmEmailId,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      landLineNumber: user.landLineNumber,
      gstNumber: user.gstNumber,
      aadharNumber: user.aadharNumber,
      country: user.country,
      firmAddress: user.firmAddress,
      state: user.state,
      city: user.city,
      zipCode: user.zipCode,
      about: user.about
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateCompanyProfile({ ...values });
        enqueueSnackbar('Update success', { variant: 'success' });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        enqueueSnackbar('Update failed. Please try later', { variant: 'error' });
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      }
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (readerEvt) => {
          const binaryString = readerEvt.target.result;
          setFieldValue('photoURL', {
            ...file,
            preview: btoa(binaryString.toString()) // URL.createObjectURL(file)
          });
        };

        reader.readAsBinaryString(file);
      }
    },
    [setFieldValue]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <UploadAvatar
                accept="image/*"
                file={values.photoURL}
                maxSize={3145728}
                onDrop={handleDrop}
                error={Boolean(touched.photoURL && errors.photoURL)}
                caption={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />

              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="Name" {...getFieldProps('firmName')} />
                  <TextField
                    fullWidth
                    disabled
                    label="Email Address"
                    {...getFieldProps('firmEmailId')}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="Phone Number" {...getFieldProps('phoneNumber')} />
                  <TextField fullWidth label="Address" {...getFieldProps('firmAddress')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled
                    label="GST Number"
                    {...getFieldProps('gstNumber')}
                  />
                  <TextField
                    fullWidth
                    disabled
                    label="Aadhar Number"
                    {...getFieldProps('aadharNumber')}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="Country" {...getFieldProps('country')} />
                  <TextField fullWidth label="State/Region" {...getFieldProps('state')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="City" {...getFieldProps('city')} />
                  <TextField fullWidth label="Zip/Code" {...getFieldProps('zipCode')} />
                </Stack>

                <TextField
                  {...getFieldProps('about')}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={4}
                  label="About"
                />
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
