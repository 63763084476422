import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
//
import { useSelector } from 'react-redux';
import moment from 'moment';
import { BaseOptionChart } from '../../charts';
import { toTwoDecimalPlaces } from '../../../utils/FloatUtils';
import { supportedDateFormats } from '../../../utils/DateUtils';
import { PAYMENT_STATUSES } from '../../../config/data/init';

// ----------------------------------------------------------------------

const getMonthlyValues = (records) => {
  let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let balances = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  records.forEach((record) => {
    const { createdAt } = record;
    const month = (moment(createdAt, supportedDateFormats).month() - 2) % 12;
    data[month] += parseFloat(record.total);
    if (record.paymentStatus.id === PAYMENT_STATUSES.DUE.id) {
      balances[month] += parseFloat(record.balance);
    }
  });
  data = data.map((x) => toTwoDecimalPlaces(x));
  balances = balances.map((x) => toTwoDecimalPlaces(x));
  return { data, balances };
};

const getFinancialYear = () => {
  const labels = [];
  const startMonth = moment().startOf('year').add(2, 'month').format('MM/DD/YYYY');
  let currentMonth = startMonth;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 12; i++) {
    labels.push(currentMonth);
    currentMonth = moment(currentMonth).add(1, 'month').format('MM/DD/YYYY');
  }
  return labels;
};

export default function AppSalesStats() {
  const { invoices } = useSelector((state) => state.invoices);
  const { purchaseOrders } = useSelector((state) => state.purchaseOrders);

  const salesData = getMonthlyValues(invoices);
  const purchaseData = getMonthlyValues(purchaseOrders);
  const profitLoss = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < salesData.data.length; i++) {
    profitLoss[i] = parseFloat(parseFloat(salesData.data[i]) - parseFloat(purchaseData.data[i]));
  }

  const labels = getFinancialYear();

  const CHART_DATA = [
    {
      name: 'Sales',
      type: 'column',
      data: salesData.data
    },
    {
      name: 'Purchases',
      type: 'area',
      data: purchaseData.data
    },
    {
      name: 'Receivables',
      type: 'column',
      data: salesData.balances
    },
    {
      name: 'Payables',
      type: 'area',
      data: purchaseData.balances
    },
    {
      name: 'Profit/Loss',
      type: 'line',
      data: profitLoss
    }
  ];

  const currentMonth = moment().month() - 2;
  const profitLossPercentage = toTwoDecimalPlaces(
    ((profitLoss[currentMonth] - profitLoss[currentMonth - 1]) / profitLoss[currentMonth]) * 100
  );

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `₹ ${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader
        title="Sales stats"
        subheader={`Profit/Loss: (${profitLossPercentage}%) than last month`}
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
