import { ADD_PAYMENT, addPayment } from './paymentsActions';
import { increaseStock } from './stockActions';
import { PAYMENT_TYPES } from '../../config/data/init';

export const FETCH_PURCHASE_ORDERS = 'FETCH_PURCHASE_ORDERS';
export const REFRESH_PURCHASE_ORDERS = 'REFRESH_PURCHASE_ORDERS';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';

export const fetchPurchaseOrders = () => ({ type: FETCH_PURCHASE_ORDERS });

export const addPurchaseOrder = (data) => (dispatch, getState) => {
  dispatch({ type: ADD_PURCHASE_ORDER, data });
  const purchaseOrderId = data.orderId;
  const orders = getState().purchaseOrders.purchaseOrders.filter(
    (order) => order.orderId === purchaseOrderId
  );

  if (data.paymentType.value !== PAYMENT_TYPES.CREDIT.value) {
    const { currentPaymentId } = getState().payments;
    const { paymentPrefix } = getState().settings.settings.billing;
    const paymentId = paymentPrefix.concat(currentPaymentId);
    const paymentData = {
      paymentId,
      supplierId: data.supplierId,
      purchaseOrderIds: orders,
      amount: data.total,
      paymentType: data.paymentType,
      createdAt: data.createdAt,
      bankAccount: data.bankAccount,
      notes: data.notes
    };
    dispatch(addPayment(paymentData));
  }
  dispatch(increaseStock(data.items));
};

export const updatePurchaseOrder = (data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PURCHASE_ORDER, data });

  const purchaseOrderId = data.orderId;
  const orders = getState().purchaseOrders.purchaseOrders.filter(
    (order) => order.orderId === purchaseOrderId
  );

  if (data.paymentType.value !== PAYMENT_TYPES.CREDIT.value) {
    const { currentPaymentId } = getState().payments;
    const { paymentPrefix } = getState().settings.settings.billing;
    const paymentId = paymentPrefix.concat(currentPaymentId);
    const paymentData = {
      paymentId,
      supplierId: data.supplierId,
      purchaseOrderIds: orders,
      amount: data.total,
      paymentType: data.paymentType,
      createdAt: data.createdAt,
      bankAccount: data.bankAccount,
      notes: data.notes
    };
    dispatch({ type: ADD_PAYMENT, data: paymentData });
  }
};
