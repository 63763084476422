import { apiService } from '../../service/apiService';

export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const REFRESH_SETTINGS = 'REFRESH_SETTINGS';
export const ADD_UNIT = 'ADD_UNIT';
export const UPDATE_UNIT = 'UPDATE_UNIT';
export const ADD_TAX = 'ADD_TAX';
export const UPDATE_TAX = 'UPDATE_TAX';
export const ADD_PAYMENT_TYPE = 'ADD_PAYMENT_TYPE';
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const UPDATE_PUSH_TOKEN = 'UPDATE_PUSH_TOKEN';
export const UPDATE_PERSONAL_PROFILE = 'UPDATE_PERSONAL_PROFILE';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const UPDATE_LOW_STOCK_ALERT = 'UPDATE_LOW_STOCK_ALERT';
export const UPDATE_ALERT_QUANTITY = 'UPDATE_ALERT_QUANTITY';
export const UPDATE_ITEM_EXPIRY_ALERT = 'UPDATE_ITEM_EXPIRY_ALERT';
export const UPDATE_EXPIRY_ALERT_BEFORE = 'UPDATE_EXPIRY_ALERT_BEFORE';
export const UPDATE_INVOICE_PREFIX = 'UPDATE_INVOICE_PREFIX';
export const UPDATE_PURCHASE_ORDER_PREFIX = 'UPDATE_PURCHASE_ORDER_PREFIX';
export const UPDATE_RECEIPT_PREFIX = 'UPDATE_RECEIPT_PREFIX';
export const UPDATE_PAYMENT_PREFIX = 'UPDATE_PAYMENT_PREFIX';
export const UPDATE_TERMS_AND_CONDITIONS = 'UPDATE_TERMS_AND_CONDITIONS';
export const UPDATE_RECEIPT_PAYMENT_TERMS_AND_CONDITIONS =
  'UPDATE_RECEIPT_PAYMENT_TERMS_AND_CONDITIONS';
export const UPDATE_THANK_YOU_NOTES = 'UPDATE_THANK_YOU_NOTES';
export const UPDATE_CURRENCY_SYMBOL = 'UPDATE_CURRENCY_SYMBOL';
export const UPDATE_PRINTING_ROLL = 'UPDATE_PRINTING_ROLL';

export const fetchSettings = () => ({ type: FETCH_SETTINGS });

export const addUnit = (unit) => ({ type: ADD_UNIT, data: unit });

export const updateUnit = (unit) => ({ type: UPDATE_UNIT, data: unit });

export const addTax = (tax) => ({ type: ADD_TAX, data: tax });

export const updateTax = (tax) => ({ type: UPDATE_TAX, data: tax });

export const addPaymentType = (paymentType) => ({ type: ADD_PAYMENT_TYPE, data: paymentType });

export const updatePaymentType = (paymentType) => ({
  type: UPDATE_PAYMENT_TYPE,
  data: paymentType
});

export const addBankAccount = (bankAccount) => ({ type: ADD_BANK_ACCOUNT, data: bankAccount });

export const updateBankAccount = (bankAccount) => ({
  type: UPDATE_BANK_ACCOUNT,
  data: bankAccount
});

export const updatePushToken = (data) => ({ type: UPDATE_PUSH_TOKEN, data });

export const updatePersonalProfile = (data) => async (dispatch, getState) => {
  const { auth } = getState().auth;
  const profileDetails = await apiService(auth).update('profile.json', data);

  if (!profileDetails.ok) {
    const message = 'Error saving profile details, please try again later';
    throw new Error(message);
  }
  dispatch({ type: UPDATE_PERSONAL_PROFILE, data });
};

export const updateCompanyProfile = (data) => async (dispatch, getState) => {
  const { auth } = getState().auth;
  const companyDetails = await apiService(auth).update('companyProfile.json', data);

  if (!companyDetails.ok) {
    const message = 'Error saving company details, please try again later';
    throw new Error(message);
  }
  dispatch({ type: UPDATE_COMPANY_PROFILE, data });
};

export const updateLowStockAlert = (data) => ({ type: UPDATE_LOW_STOCK_ALERT, data });

export const updateAlertQuantity = (data) => ({ type: UPDATE_ALERT_QUANTITY, data });

export const updateItemExpiryAlert = (data) => ({ type: UPDATE_ITEM_EXPIRY_ALERT, data });

export const updateExpiryAlertBefore = (data) => ({ type: UPDATE_EXPIRY_ALERT_BEFORE, data });

export const updateInvoicePrefix = (data) => ({ type: UPDATE_INVOICE_PREFIX, data });

export const updatePurchaseOrderPrefix = (data) => ({
  type: UPDATE_PURCHASE_ORDER_PREFIX,
  data
});

export const updateReceiptPrefix = (data) => ({ type: UPDATE_RECEIPT_PREFIX, data });

export const updatePaymentPrefix = (data) => ({ type: UPDATE_PAYMENT_PREFIX, data });

export const updateTermsAndConditions = (data) => ({
  type: UPDATE_TERMS_AND_CONDITIONS,
  data
});

export const updateReceiptPaymentTermsAndConditions = (data) => ({
  type: UPDATE_RECEIPT_PAYMENT_TERMS_AND_CONDITIONS,
  data
});

export const updateThankYouNotes = (data) => ({ type: UPDATE_THANK_YOU_NOTES, data });

export const updateCurrencySymbol = (data) => ({ type: UPDATE_CURRENCY_SYMBOL, data });

export const updatePrintingRoll = (data) => ({ type: UPDATE_PRINTING_ROLL, data });
