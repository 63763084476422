import {
  CURRENCY_SYMBOLS,
  // eslint-disable-next-line import/named
  PAYMENT_TYPES,
  TAX_RATES,
  UNITS,
  PRINTING_ROLL_CONFIG,
  DEFUALT_BANK_ACCOUNT_NONE
} from '../../config/data/init';
import {
  ADD_BANK_ACCOUNT,
  ADD_PAYMENT_TYPE,
  ADD_TAX,
  ADD_UNIT,
  FETCH_SETTINGS,
  REFRESH_SETTINGS,
  UPDATE_ALERT_QUANTITY,
  UPDATE_BANK_ACCOUNT,
  UPDATE_COMPANY_PROFILE,
  UPDATE_CURRENCY_SYMBOL,
  UPDATE_EXPIRY_ALERT_BEFORE,
  UPDATE_INVOICE_PREFIX,
  UPDATE_ITEM_EXPIRY_ALERT,
  UPDATE_LOW_STOCK_ALERT,
  UPDATE_PAYMENT_PREFIX,
  UPDATE_PAYMENT_TYPE,
  UPDATE_PERSONAL_PROFILE,
  UPDATE_PRINTING_ROLL,
  UPDATE_PURCHASE_ORDER_PREFIX,
  UPDATE_PUSH_TOKEN,
  UPDATE_RECEIPT_PREFIX,
  UPDATE_TAX,
  UPDATE_TERMS_AND_CONDITIONS,
  UPDATE_RECEIPT_PAYMENT_TERMS_AND_CONDITIONS,
  UPDATE_THANK_YOU_NOTES,
  UPDATE_UNIT
} from '../actions/settingsActions';
import Unit from '../../models/Unit';
import TaxRate from '../../models/TaxRate';
import PaymentType from '../../models/PaymentType';
import BankAccount from '../../models/BankAccount';

const initialState = {
  settings: {
    user: {
      pushToken: null,
      profile: {
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: '9876543210',
        address: 'Address',
        emailId: 'test@test.com',
        country: 'India',
        state: 'Andhra Pradesh',
        city: 'Bhimavaram',
        zipCode: '534201',
        about: 'Business Owner',
        photoURL: {
          preview: null,
          file: null
        }
      },
      companyProfile: {
        firmName: 'Firm Name',
        firmEmailId: 'test@firm.com',
        firmAddress: 'Firm Address',
        phoneNumber: '9876543210',
        landLineNumber: '',
        gstNumber: 'GSTINABCDE1234G1ZB',
        aadharNumber: '123412341234',
        country: 'India',
        state: 'Andhra Pradesh',
        city: 'Bhimavaram',
        zipCode: '534201',
        about: 'Business Owner',
        bankDetails: [DEFUALT_BANK_ACCOUNT_NONE],
        photoURL: {
          preview: null,
          file: null
        }
      }
    },
    menu: [],
    units: UNITS,
    taxSlabs: TAX_RATES,
    paymentTypes: PAYMENT_TYPES,
    bankAccounts: [],
    stock: {
      lowStockAlert: true,
      alertQuantity: '5',
      itemExpiryAlert: false,
      expiryAlertBefore: '10'
    },
    billing: {
      invoicePrefix: 'INV-',
      purchaseOrderPrefix: 'PO-',
      receiptPrefix: 'RCPT-',
      paymentPrefix: 'PYMT-',
      currencySymbol: CURRENCY_SYMBOLS[0],
      termsAndConditions:
        'GST is included in the selling price \nTaxes shown separately are mandated by GST regulations',
      receiptPaymentTerms:
        'The above payment has been made by the customer. \nThe owner has received the payment mentioned above and balance is payable, if any.',
      thankYouNotes: 'Thank you for shopping with us! \nVisit again !!'
    },
    printing: {
      printingRoll: PRINTING_ROLL_CONFIG[0]
    }
  }
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS:
      return state;
    case REFRESH_SETTINGS: {
      const userSettings = action.data;
      return {
        ...state,
        userSettings
      };
    }
    case UPDATE_PUSH_TOKEN: {
      const pushToken = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          user: {
            ...state.settings.user,
            pushToken
          }
        }
      };
    }
    case UPDATE_PERSONAL_PROFILE: {
      const userProfile = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          user: {
            ...state.settings.user,
            profile: userProfile
          }
        }
      };
    }
    case UPDATE_COMPANY_PROFILE: {
      const companyProfile = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          user: {
            ...state.settings.user,
            companyProfile
          }
        }
      };
    }
    case ADD_UNIT: {
      const newUnits = state.settings.units;
      const { data } = action;
      const unit = new Unit(data.id, data.label, data.value);
      newUnits[unit.id] = unit;
      return {
        ...state,
        settings: {
          ...state.settings,
          units: newUnits
        }
      };
    }
    case UPDATE_UNIT: {
      const { units } = state.settings;
      const passedUnit = action.data;
      units[passedUnit.id] = passedUnit;
      return {
        ...state,
        settings: {
          ...state.settings,
          units
        }
      };
    }
    case ADD_TAX: {
      const newTaxRates = state.settings.taxSlabs;
      const taxData = action.data;
      const tax = new TaxRate(taxData.id, taxData.label, taxData.value);
      newTaxRates[tax.id] = tax;
      return {
        ...state,
        settings: {
          ...state.settings,
          taxSlabs: newTaxRates
        }
      };
    }
    case UPDATE_TAX: {
      const taxSlabs = state.settings.units;
      const passedTaxRate = action.data;
      taxSlabs[passedTaxRate.id] = passedTaxRate;
      return {
        ...state,
        settings: {
          ...state.settings,
          taxSlabs
        }
      };
    }
    case ADD_PAYMENT_TYPE: {
      const newPaymentTypes = state.settings.paymentTypes;
      const paymentTypeData = action.data;
      const paymentType = new PaymentType(
        paymentTypeData.id,
        paymentTypeData.label,
        paymentTypeData.value
      );
      newPaymentTypes[paymentType.id] = paymentType;
      return {
        ...state,
        settings: {
          ...state.settings,
          paymentTypes: newPaymentTypes
        }
      };
    }
    case UPDATE_PAYMENT_TYPE: {
      const { paymentTypes } = state.settings;
      const passedPaymentType = action.data;
      paymentTypes[passedPaymentType.id] = passedPaymentType;
      return {
        ...state,
        settings: {
          ...state.settings,
          paymentTypes
        }
      };
    }
    case ADD_BANK_ACCOUNT: {
      const accounts = state.settings.bankAccounts;
      const bankAccount = action.data;
      const newBankAccount = new BankAccount(
        bankAccount.accountNumber,
        bankAccount.accountName,
        bankAccount.accountNumber,
        bankAccount.bankName,
        bankAccount.ifscCode,
        bankAccount.branchName
      );
      const updatedBankAccounts = [...accounts, newBankAccount];
      // console.log(`Inside Bank Reducer:${JSON.stringify(updatedBankAccounts)}`);
      return {
        ...state,
        settings: {
          ...state.settings,
          bankAccounts: updatedBankAccounts
        }
      };
    }
    case UPDATE_BANK_ACCOUNT: {
      const accounts = state.settings.bankAccounts;
      const bankAcc = action.data;
      const newBankAccount = new BankAccount(
        bankAcc.accountNumber,
        bankAcc.accountName,
        bankAcc.accountNumber,
        bankAcc.bankName,
        bankAcc.ifscCode,
        bankAcc.branchName
      );
      const bankAccountsWithoutUpdatedOne = accounts.filter(
        (acc) => acc.id.toString() !== newBankAccount.id.toString()
      );
      // console.log(`Inside Update Bank Reducer:${JSON.stringify(bankAccountsWithoutUpdatedOne)}`);
      return {
        ...state,
        settings: {
          ...state.settings,
          bankAccounts: [...bankAccountsWithoutUpdatedOne, newBankAccount]
        }
      };
    }
    case UPDATE_LOW_STOCK_ALERT: {
      const lowStockAlert = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          stock: {
            ...state.settings.stock,
            lowStockAlert
          }
        }
      };
    }
    case UPDATE_ALERT_QUANTITY: {
      const alertQuantity = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          stock: {
            ...state.settings.stock,
            alertQuantity
          }
        }
      };
    }
    case UPDATE_ITEM_EXPIRY_ALERT: {
      const itemExpiryAlert = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          stock: {
            ...state.settings.stock,
            itemExpiryAlert
          }
        }
      };
    }
    case UPDATE_EXPIRY_ALERT_BEFORE: {
      const expiryAlertBefore = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          stock: {
            ...state.settings.stock,
            expiryAlertBefore
          }
        }
      };
    }
    case UPDATE_INVOICE_PREFIX: {
      const invoicePrefix = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            invoicePrefix
          }
        }
      };
    }
    case UPDATE_PURCHASE_ORDER_PREFIX: {
      const purchaseOrderPrefix = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            purchaseOrderPrefix
          }
        }
      };
    }
    case UPDATE_RECEIPT_PREFIX: {
      const receiptPrefix = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            receiptPrefix
          }
        }
      };
    }
    case UPDATE_PAYMENT_PREFIX: {
      const paymentPrefix = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            paymentPrefix
          }
        }
      };
    }
    case UPDATE_TERMS_AND_CONDITIONS: {
      const termsAndConditions = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            termsAndConditions
          }
        }
      };
    }
    case UPDATE_RECEIPT_PAYMENT_TERMS_AND_CONDITIONS: {
      const receiptPaymentTerms = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            receiptPaymentTerms
          }
        }
      };
    }
    case UPDATE_THANK_YOU_NOTES: {
      const thankYouNotes = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            thankYouNotes
          }
        }
      };
    }
    case UPDATE_CURRENCY_SYMBOL: {
      const currencySymbol = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          billing: {
            ...state.settings.billing,
            currencySymbol
          }
        }
      };
    }
    case UPDATE_PRINTING_ROLL: {
      const printingRoll = action.data;
      return {
        ...state,
        settings: {
          ...state.settings,
          printing: {
            ...state.settings.printing,
            printingRoll
          }
        }
      };
    }
    default:
      return state;
  }
};

export default settingsReducer;
