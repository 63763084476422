import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
import TaxRate from '../models/TaxRate';
import { toTwoDecimalPlaces } from '../utils/FloatUtils';
import { convertNumberToWordsWithDecimal } from '../utils/formatNumber';

export default function InvoiceItems(props) {
  // Defining a state named rows
  // which we can update by calling on setRows function
  const [rows, setRows] = useState([
    {
      id: 1,
      title: '',
      description: '',
      quantity: '',
      availableQty: '',
      mrp: '',
      price: '',
      itemDiscount: 0,
      amount: '',
      tax: '',
      unit: ''
    }
  ]);

  const [totals, setTotals] = useState({
    subTotal: 0,
    itemDiscountTotal: 0,
    cgstTotal: 0,
    sgstTotal: 0,
    totalAmount: 0,
    overallDiscount: 0,
    shippingCharges: 0,
    taxTotal: 0
  });

  // Initial states
  const [open, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(true);
  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const isInvoice = props.userType === 'INVOICE';

  useEffect(() => {
    if (props.type === 'EDIT') {
      // console.log(`Inside useEffect: ${JSON.stringify(props.selectedItems)}`);
      setRows(props.selectedItems);
      updateTotals(props.selectedItems);
    }
  }, [props.type, props.selectedItems]);

  // Function For closing the alert snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Function For adding new row object
  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        title: '',
        description: '',
        quantity: '',
        availableQty: '',
        mrp: '',
        price: '',
        itemDiscount: 0,
        amount: '',
        tax: '',
        unit: '',
        hsncode: ''
      }
    ]);
    setEdit(true);
  };

  // Function to handle edit
  const handleEdit = (i) => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEdit(!isEdit);
  };

  // Function to handle save
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    setDisable(true);
    setOpen(true);
  };

  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
    updateTotals(list);
  };

  const handleOverallDiscount = (e) => {
    setDisable(false);
    const { value } = e.target;
    const finalTotals = { ...totals, overallDiscount: value };
    setTotals(finalTotals);
    props.onUpdate(rows, finalTotals);
  };

  const handleShippingCharges = (e) => {
    setDisable(false);
    const { value } = e.target;
    const finalTotals = { ...totals, shippingCharges: value };
    setTotals(finalTotals);
    props.onUpdate(rows, finalTotals);
  };

  const updateTotals = (rows) => {
    let subTotal = 0;
    let itemDiscountTotal = 0;
    let taxTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let totalAmount = 0;

    rows.forEach((row) => {
      row.amount = row.quantity * (row.mrp - row.itemDiscount);
      itemDiscountTotal += row.quantity * row.itemDiscount;
      totalAmount += row.amount;
      taxTotal += (row.amount * (row.tax.value * 2)) / 100;
    });
    subTotal = totalAmount - taxTotal;
    cgstTotal = taxTotal / 2;
    sgstTotal = taxTotal / 2;

    const finalTotals = {
      subTotal,
      itemDiscountTotal,
      cgstTotal,
      sgstTotal,
      totalAmount,
      taxTotal
    };
    setTotals({ ...totals, ...finalTotals });
    props.onUpdate(rows, { ...totals, ...finalTotals });
  };

  const handleAutoCompleteInputChange = (e, index, val) => {
    setDisable(false);
    if (val) {
      const list = [...rows];
      list[index].id = val.title;
      list[index].title = val.title;
      list[index].description = val.description;
      list[index].tax = new TaxRate(val.tax.id, `${val.tax.value / 2}%`, val.tax.value / 2);
      list[index].unit = val.unit;
      list[index].hsncode = val.hsncode;
      list[index].availableQty = val.quantity;
      list[index].price = val.mrp;
      list[index].itemDiscount = val.itemDiscount || 0;
      // console.log('List:');
      // console.log([...rows]);
      setRows(list);
    }
  };

  // Showing delete confirmation to users
  const handleConfirm = () => {
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where
  // user click yes delete a specific row of id:i
  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
    setShowConfirm(false);
    updateTotals(list);
  };

  // Handle the case of delete confirmation
  // where user click no
  const handleNo = () => {
    setShowConfirm(false);
  };

  return (
    <TableBody>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Record saved successfully!
        </Alert>
      </Snackbar>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" gutterBottom>
          Invoice Items
        </Typography>
        <Button
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
          onClick={handleAdd}
          disabled={props.type === 'EDIT'}
        >
          Add Item
        </Button>
      </Stack>
      <Stack spacing={2}>
        <Table cellSpacing={2}>
          <TableHead>
            <TableRow>
              <TableCell width="25%">Item Name</TableCell>
              <TableCell width="12%">Quantity</TableCell>
              <TableCell width="12%">Price</TableCell>
              <TableCell width="8%">Discount/Item</TableCell>
              <TableCell width="10%">CGST</TableCell>
              <TableCell width="10%">SGST</TableCell>
              <TableCell width="12%">Amount</TableCell>
              <TableCell width="5%" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <>
                <TableRow>
                  <TableCell>
                    <Autocomplete
                      options={props.items}
                      onChange={(event, value) => handleAutoCompleteInputChange(event, i, value)}
                      getOptionLabel={(option) => `${option.title}`}
                      fullWidth
                      disabled={props.type === 'EDIT'}
                      renderInput={(params) => (
                        <TextField
                          value={row.title}
                          {...params}
                          name="title"
                          onChange={(e) => handleInputChange(e, i)}
                          disabled={!isEdit}
                        />
                      )}
                    />
                    <Typography variant="body1" fontSize={12} fontWeight="bold">
                      Available Qty:
                      {row.availableQty} @ {toTwoDecimalPlaces(row.price)} / {row.unit.label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.quantity}
                      name="quantity"
                      onChange={(e) => handleInputChange(e, i)}
                      disabled={props.type === 'EDIT'}
                    />
                    <Typography>
                      <br />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.mrp}
                      name="mrp"
                      onChange={(e) => handleInputChange(e, i)}
                      disabled={props.type === 'EDIT'}
                    />
                    <Typography>
                      <br />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.itemDiscount || 0}
                      name="itemDiscount"
                      onChange={(e) => handleInputChange(e, i)}
                      disabled={props.type === 'EDIT'}
                    />
                    <Typography variant="body1" fontSize={12} fontWeight="bold">
                      Total: {toTwoDecimalPlaces(row.itemDiscount * row.quantity)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={toTwoDecimalPlaces(
                        (row.quantity * (row.mrp - row.itemDiscount) * row.tax.value) / 100
                      )}
                      name="cgst_amount"
                      disabled
                    />
                    <Typography variant="body1" fontSize={12} fontWeight="bold">
                      @ {row.tax.label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={toTwoDecimalPlaces(
                        (row.quantity * (row.mrp - row.itemDiscount) * row.tax.value) / 100
                      )}
                      name="sgst_amount"
                      disabled
                    />
                    <Typography variant="body1" fontSize={12} fontWeight="bold">
                      @ {row.tax.label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={toTwoDecimalPlaces(row.quantity * (row.mrp - row.itemDiscount))}
                      name="amount"
                      disabled
                    />
                    <Typography>
                      <br />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<Icon icon={minusFill} />}
                      onClick={handleConfirm}
                      disabled={props.type === 'EDIT'}
                    >
                      Delete
                    </Button>
                    <Typography>
                      <br />
                    </Typography>
                  </TableCell>
                  {showConfirm && (
                    <Dialog
                      open={showConfirm}
                      onClose={handleNo}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure to delete
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleRemoveClick(i)} color="primary" autoFocus>
                          Yes
                        </Button>
                        <Button onClick={handleNo} color="primary" autoFocus>
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>
                    <TextField
                      value={row.description}
                      name="description"
                      onChange={(e) => handleInputChange(e, i)}
                      disabled={props.type === 'EDIT'}
                      placeholder="Description"
                    />
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack width="40%">
          <Typography variant="h6" gutterBottom>
            Amount in words
          </Typography>
          <Typography gutterBottom>
            Rupees{' '}
            {convertNumberToWordsWithDecimal(
              parseFloat(totals.totalAmount) -
                parseFloat(totals.overallDiscount) +
                parseFloat(totals.shippingCharges)
            )}{' '}
            Only
          </Typography>
        </Stack>
        <Stack width="60%">
          <Table cellSpacing={2}>
            <TableBody>
              <TableRow>
                <TableCell>Sub Total</TableCell>
                <TableCell>₹ {toTwoDecimalPlaces(totals.subTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CGST Total</TableCell>
                <TableCell>₹ {toTwoDecimalPlaces(totals.cgstTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>SGST Total</TableCell>
                <TableCell>₹ {toTwoDecimalPlaces(totals.sgstTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Item Discount Total</TableCell>
                <TableCell>₹ {toTwoDecimalPlaces(totals.itemDiscountTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Overall Discount</TableCell>
                <TableCell>
                  <TextField
                    value={totals.overallDiscount}
                    name="overallDiscount"
                    onChange={(e) => handleOverallDiscount(e)}
                    disabled={props.type === 'EDIT'}
                    error={
                      totals.overallDiscount < 0 || totals.overallDiscount > totals.totalAmount
                    }
                    helperText="Discount cannot be less than zero or greater than total amount"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shipping Charges</TableCell>
                <TableCell>
                  <TextField
                    value={totals.shippingCharges}
                    name="shippingCharges"
                    onChange={(e) => handleShippingCharges(e)}
                    disabled={props.type === 'EDIT'}
                    error={totals.shippingCharges < 0}
                    helperText="Shipping charges cannot be less than zero"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Amount</TableCell>
                <TableCell>
                  ₹{' '}
                  {toTwoDecimalPlaces(
                    parseFloat(totals.totalAmount) -
                      parseFloat(totals.overallDiscount) +
                      parseFloat(totals.shippingCharges)
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </TableBody>
  );
}
