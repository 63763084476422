import { AUTHENTICATE, LOGIN, LOGOUT } from '../actions/auth';

const initialState = {
  auth: {
    token: null,
    userId: null,
    expirationTime: null,
    expiryDate: null
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        auth: {
          ...state.auth,
          token: action.token,
          userId: action.userId,
          expiryDate: action.expiryDate
        }
      };
    case AUTHENTICATE:
      return {
        ...state,
        auth: {
          ...state.auth,
          token: action.userToken,
          userId: action.userId,
          expirationTime: action.expirationTime
        }
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
