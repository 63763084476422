// material
import { Autocomplete, Container, Divider, Stack, TextField, Typography } from '@material-ui/core';
// components
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as invoiceActions from '../store/actions/invoicesActions';
import * as purchaseActions from '../store/actions/purchaseActions';
import Page from '../components/Page';
import Customer from '../models/Customer';
import Supplier from '../models/Supplier';
import InvoiceItems from './InvoiceItems';
import Scrollbar from '../components/Scrollbar';
import {
  DEFAULT_CUSTOMER_NONE,
  DEFUALT_PAYMENT_TYPE_NONE,
  PAYMENT_STATUSES,
  PAYMENT_TYPES
} from '../config/data/init';
import { toTwoDecimalPlaces } from '../utils/FloatUtils';
//
// ----------------------------------------------------------------------
export default function AddInvoicePO(props) {
  const { id } = useParams();
  const { type, userType } = props;
  const [invoicePO, setInvoicePO] = useState({
    user: '',
    invoiceId: '',
    supplierInvoiceId: '',
    invoiceDate: '',
    personThrough: '',
    invoiceItems: [],
    paymentType: ''
  });
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [invoiceTotals, setInvoiceTotals] = useState({});
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();
  const { suppliers } = useSelector((state) => state.suppliers);
  const { customers } = useSelector((state) => state.customers);
  const { invoices } = useSelector((state) => state.invoices);
  const { purchaseOrders } = useSelector((state) => state.purchaseOrders);
  const { items } = useSelector((state) => state.stock.stock);

  useEffect(() => {
    setTitle(renderTitle(type));
    formik.setTouched({}, false);
  }, [type]);

  const populateUserDetails = () => {
    let invoice = {};
    let user = {};
    if (userType === 'INVOICE') {
      invoice = invoices.find((invoice) => invoice.id.toString() === id.toString());
      user = customers.find((customer) => customer.id.toString() === invoice.customerId.toString());
    } else {
      invoice = purchaseOrders.find((order) => order.id.toString() === id.toString());
      user = suppliers.find((supplier) => supplier.id.toString() === invoice.supplierId.toString());
    }
    setInvoicePO({
      user,
      invoiceId: invoice.invoiceId || invoice.orderId,
      supplierInvoiceId: invoice.supplierInvoiceId,
      invoiceDate: invoice.createdAt,
      personThrough: invoice.personThrough,
      invoiceItems: invoice.items,
      paymentType: invoice.paymentType
    });
  };

  const renderTitle = (type) => {
    let title = '';
    const user = userType === 'INVOICE' ? 'Invoice' : 'Purchase Order';
    switch (type) {
      case 'ADD':
        title = `Add ${user}`;
        break;
      case 'EDIT':
        title = `Edit ${user}`;
        populateUserDetails();
        break;
      default:
        title = `${user} Details`;
        break;
    }
    return title;
  };
  const navigate = useNavigate();

  const InvoicePOSchema = Yup.object().shape({
    user: Yup.object().required('Customer/Supplier required').nullable(),
    invoiceId: Yup.string().required('Invoice Id required'),
    invoiceDate: Yup.date().required('Invoice Date required'),
    paymentType: Yup.object().required('Payment Type required').nullable()
  });

  const formik = useFormik({
    initialValues: invoicePO,
    enableReinitialize: true,
    validationSchema: InvoicePOSchema,
    onSubmit: () => {
      setInvoicePO({
        ...invoicePO,
        user: getFieldProps('user').value
      });
      // console.log('inside submit');
      // console.log(`InvoicePO: ${JSON.stringify(invoicePO)}`);
      const userFound =
        userType === 'INVOICE'
          ? customers.find(
              (customer) =>
                customer.id.toString() === getFieldProps('user').value.phoneNumber.toString()
            )
          : suppliers.find(
              (supplier) =>
                supplier.id.toString() === getFieldProps('user').value.phoneNumber.toString()
            );
      const paymentType = getFieldProps('paymentType').value;
      if (type === 'ADD') {
        if (userType === 'INVOICE') {
          // console.log(getFieldProps('paymentType').value);
          dispatch(
            invoiceActions.addInvoice({
              invoiceId: getFieldProps('invoiceId').value,
              customerId: userFound.id,
              items: selectedInvoiceItems,
              subTotal: invoiceTotals.subTotal,
              taxTotal: invoiceTotals.taxTotal,
              itemDiscountTotal: invoiceTotals.itemDiscountTotal,
              overallDiscountTotal: invoiceTotals.overallDiscount,
              shippingCharges: invoiceTotals.shippingCharges,
              roundOff: 0.0,
              total: toTwoDecimalPlaces(
                parseFloat(invoiceTotals.totalAmount) -
                  parseFloat(invoiceTotals.overallDiscount) +
                  parseFloat(invoiceTotals.shippingCharges)
              ),
              paymentType,
              createdAt: getFieldProps('invoiceDate').value,
              paymentStatus:
                paymentType.value === PAYMENT_TYPES.CASH.value
                  ? PAYMENT_STATUSES.PAID
                  : PAYMENT_STATUSES.DUE,
              personThrough: getFieldProps('personThrough').value
            })
          );
        } else {
          dispatch(
            purchaseActions.addPurchaseOrder({
              supplierInvoiceId: getFieldProps('supplierInvoiceId').value,
              orderId: getFieldProps('invoiceId').value,
              supplierId: userFound.id,
              items: selectedInvoiceItems,
              subTotal: invoiceTotals.subTotal,
              taxTotal: invoiceTotals.taxTotal,
              itemDiscountTotal: invoiceTotals.itemDiscountTotal,
              overallDiscountTotal: invoiceTotals.overallDiscount,
              shippingCharges: invoiceTotals.shippingCharges,
              roundOff: 0.0,
              total: toTwoDecimalPlaces(
                parseFloat(invoiceTotals.totalAmount) -
                  parseFloat(invoiceTotals.overallDiscount) +
                  parseFloat(invoiceTotals.shippingCharges)
              ),
              paymentType,
              createdAt: getFieldProps('invoiceDate').value,
              paymentStatus:
                paymentType.value === PAYMENT_TYPES.CASH.value
                  ? PAYMENT_STATUSES.PAID
                  : PAYMENT_STATUSES.DUE,
              personThrough: getFieldProps('personThrough').value
            })
          );
        }
      } else if (type === 'EDIT') {
        // eslint-disable-next-line no-empty
        if (userType === 'INVOICE') {
          // eslint-disable-next-line no-empty
        } else {
        }
      }
      if (type === 'EDIT') {
        navigate('./../../', { replace: true });
      } else {
        navigate('./../', { replace: true });
      }
    }
  });

  const onUpdateHandler = (items, totals) => {
    // console.log(`UpdateHandlerItems: ${JSON.stringify(items)}`);
    setSelectedInvoiceItems(items);
    setInvoiceTotals(totals);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;
  return (
    <Page title={`Dashboard: ${title} | Quick Bill`}>
      <Scrollbar>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          </Stack>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Autocomplete
                    defaultValue={DEFAULT_CUSTOMER_NONE}
                    options={userType === 'INVOICE' ? customers : suppliers}
                    onChange={(event, value) => setFieldValue('user', value)}
                    getOptionLabel={(option) => `${option.name} -- ${option.phoneNumber}`}
                    fullWidth
                    disabled={type !== 'ADD'}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Full Name"
                        {...params}
                        {...getFieldProps('user')}
                        error={Boolean(touched.user && errors.user)}
                        helperText={touched.user && errors.user}
                      />
                    )}
                  />

                  <TextField
                    fullWidth
                    label={`${userType === 'INVOICE' ? 'Invoice' : 'Purchase Order'} Id`}
                    {...getFieldProps('invoiceId')}
                    error={Boolean(touched.invoiceId && errors.invoiceId)}
                    helperText={touched.invoiceId && errors.invoiceId}
                    disabled={type !== 'ADD'}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack width="50%">
                    <TextField
                      fullWidth
                      multiline
                      value={
                        getFieldProps('user').value !== undefined &&
                        getFieldProps('user').value !== null &&
                        getFieldProps('user').value !== ''
                          ? `${getFieldProps('user').value.name}\n${
                              getFieldProps('user').value.phoneNumber
                            }\n${getFieldProps('user').value.shippingAddress}\n${
                              getFieldProps('user').value.gstNumber
                            }`
                          : ``
                      }
                      label={`${userType === 'INVOICE' ? 'Customer' : 'Supplier'} details`}
                      error={Boolean(touched.user && errors.user)}
                      helperText={touched.user && errors.user}
                      disabled
                    />
                  </Stack>
                  <Stack spacing={2} width="50%">
                    <TextField
                      fullWidth
                      {...getFieldProps('invoiceDate')}
                      error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                      helperText={touched.invoiceDate && errors.invoiceDate}
                      type="date"
                      disabled={type !== 'ADD'}
                    />
                    {userType !== 'INVOICE' && (
                      <TextField
                        fullWidth
                        label="Supplier Invoice Id"
                        {...getFieldProps('supplierInvoiceId')}
                        disabled={type !== 'ADD'}
                      />
                    )}
                    <TextField
                      fullWidth
                      label="Person Through"
                      {...getFieldProps('personThrough')}
                      disabled={type !== 'ADD'}
                    />
                  </Stack>
                </Stack>
                <Divider />
                <InvoiceItems
                  items={items}
                  selectedItems={invoicePO.invoiceItems}
                  type={id !== undefined ? 'EDIT' : ''}
                  onUpdate={onUpdateHandler}
                  userType={userType}
                />
                <Divider />
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Stack width="50%">
                    <Typography variant="h6" gutterBottom>
                      Terms and Conditions
                    </Typography>
                    <Typography gutterBottom>Payment Terms</Typography>
                  </Stack>
                  <Stack width="50%">
                    <Autocomplete
                      defaultValue={DEFUALT_PAYMENT_TYPE_NONE}
                      options={Object.values(PAYMENT_TYPES)}
                      onChange={(event, value) => setFieldValue('paymentType', value)}
                      getOptionLabel={(option) => `${option.label}`}
                      fullWidth
                      disabled={type !== 'ADD'}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Payment Type"
                          {...params}
                          {...getFieldProps('paymentType')}
                          error={Boolean(touched.paymentType && errors.paymentType)}
                          helperText={touched.paymentType && errors.paymentType}
                        />
                      )}
                    />
                  </Stack>
                </Stack>

                {(type === 'ADD' || type === 'EDIT') && (
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={type !== 'ADD'}
                  >
                    Save Details
                  </LoadingButton>
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Container>
      </Scrollbar>
    </Page>
  );
}
