import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@material-ui/lab';
import parse from 'html-react-parser';
import { useReactToPrint } from 'react-to-print';
import { Icon } from '@iconify/react';
import cloudDownload from '@iconify/icons-eva/cloud-download-fill';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Customer from '../models/Customer';
import { UserReportTemplate } from '../models/UserReportTemplate';
import { formatDate, isValidDateRange } from '../utils/DateUtils';
import { SalesReportTemplate } from '../models/SalesReportTemplate';
import { StockReportTemplate } from '../models/StockReportTemplate';
import { DEFAULT_CUSTOMER_NONE } from '../config/data/init';

const userReportSchema = Yup.object().shape({
  user: Yup.object().required('Customer/Supplier required').nullable(),
  from: Yup.date().required('From Date required').nullable(),
  to: Yup.date().required('To Date required').nullable()
});

const salesPurchaseReportSchema = Yup.object().shape({
  from: Yup.date().required('From Date required').nullable(),
  to: Yup.date().required('To Date required').nullable()
});

const stockReportSchema = Yup.object().shape({});

const getReportQuerySchema = (type) => {
  if (type === 'CUSTOMER' || type === 'SUPPLIER') {
    return userReportSchema;
  }
  if (type === 'SALES' || type === 'PURCHASE') {
    return salesPurchaseReportSchema;
  }
  return stockReportSchema;
};

export default function CustomerSupplierReport(props) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const { type } = props;
  const isUserReport = type === 'CUSTOMER' || type === 'SUPPLIER';
  const isSalesPurchaseReport = type === 'SALES' || type === 'PURCHASE';
  const isCustomer = type === 'CUSTOMER';
  const isSales = type === 'SALES';

  const [userReport, setUserReport] = useState('');
  const [title, setTitle] = useState('');
  const [reportQuery, setReportQuery] = useState({
    user: '',
    from: '',
    to: ''
  });

  const { suppliers } = useSelector((state) => state.suppliers);
  const { customers } = useSelector((state) => state.customers);
  const { invoices } = useSelector((state) => state.invoices);
  const { purchaseOrders } = useSelector((state) => state.purchaseOrders);
  const { receipts } = useSelector((state) => state.receipts);
  const { payments } = useSelector((state) => state.payments);
  const { settings } = useSelector((state) => state.settings);
  const { items } = useSelector((state) => state.stock.stock);

  const { currencySymbol } = settings.billing;
  const firmDetails = settings.user.companyProfile;

  useEffect(() => {
    setTitle(renderTitle(type));
    const clearFormInput = {
      user: '',
      from: '',
      to: ''
    };
    setReportQuery(clearFormInput);
    setUserReport('');
    formik.setTouched({}, false);
    formik.resetForm({ values: clearFormInput });
  }, [type]);

  const reportQuerySchema = getReportQuerySchema(type);

  const getSalesInvoices = (from, to) =>
    isSales
      ? invoices.filter((invoice) => isValidDateRange(from, formatDate(invoice.createdAt), to))
      : purchaseOrders.filter((order) => isValidDateRange(from, formatDate(order.createdAt), to));

  const getUserInvoices = (user, from, to) =>
    isCustomer
      ? invoices
          .filter((invoice) => invoice.customerId.toString() === user.id.toString())
          .filter((invoice) => isValidDateRange(from, formatDate(invoice.createdAt), to))
      : purchaseOrders
          .filter((order) => order.supplierId.toString() === user.id.toString())
          .filter((order) => isValidDateRange(from, formatDate(order.createdAt), to));

  const getUserReceipts = (user, from, to) =>
    isCustomer
      ? receipts
          .filter((receipt) => receipt.customerId.toString() === user.id.toString())
          .filter((receipt) => isValidDateRange(from, formatDate(receipt.createdAt), to))
      : payments
          .filter((payment) => payment.supplierId.toString() === user.id.toString())
          .filter((payment) => isValidDateRange(from, formatDate(payment.createdAt), to));

  const formik = useFormik({
    initialValues: reportQuery,
    enableReinitialize: true,
    validationSchema: reportQuerySchema,
    onSubmit: () => {
      const user = getFieldProps('user').value;
      const from = formatDate(getFieldProps('from').value);
      const to = formatDate(getFieldProps('to').value);
      // console.log(`${user} ${from} ${to}`);
      setUserReport('');
      setReportQuery({
        ...reportQuery,
        user,
        from,
        to
      });
      if (isUserReport) {
        const userInvoices = getUserInvoices(user, from, to);
        const userReceipts = getUserReceipts(user, from, to);
        const userReportHTML = UserReportTemplate(
          type,
          user,
          from,
          to,
          userInvoices,
          userReceipts,
          currencySymbol,
          firmDetails
        );
        setUserReport(userReportHTML);
      } else if (isSalesPurchaseReport) {
        const salesInvoices = getSalesInvoices(from, to);
        const userReportHTML = SalesReportTemplate(
          type,
          from,
          to,
          salesInvoices,
          currencySymbol,
          firmDetails,
          isSales ? customers : suppliers
        );
        setUserReport(userReportHTML);
      } else {
        const stockItems = items;
        const stockReportHTML = StockReportTemplate(stockItems, currencySymbol);
        setUserReport(stockReportHTML);
      }
    }
  });

  const renderTitle = (type) => {
    let title = '';
    switch (type) {
      case 'CUSTOMER':
        title = 'Customer Report';
        break;
      case 'SUPPLIER':
        title = 'Supplier Report';
        break;
      case 'SALES':
        title = 'Sales Report';
        break;
      case 'PURCHASE':
        title = 'Purchase Report';
        break;
      case 'STOCK':
        title = 'Stock Report';
        break;
      default:
        title = '';
    }
    return title;
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <Page title={`Dashboard: ${title} | Quick Bill`}>
      <Scrollbar>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          </Stack>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {(type === 'CUSTOMER' || type === 'SUPPLIER') && (
                  <>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Autocomplete
                        defaultValue={DEFAULT_CUSTOMER_NONE}
                        options={type === 'CUSTOMER' ? customers : suppliers}
                        onChange={(event, value) => setFieldValue('user', value)}
                        getOptionLabel={(option) => `${option.name} -- ${option.phoneNumber}`}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            label="Full Name"
                            {...params}
                            {...getFieldProps('user')}
                            error={Boolean(touched.user && errors.user)}
                            helperText={touched.user && errors.user}
                          />
                        )}
                      />

                      <TextField
                        fullWidth
                        label="From"
                        {...getFieldProps('from')}
                        error={Boolean(touched.from && errors.from)}
                        helperText={touched.from && errors.from}
                        type="date"
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Stack width="50%">
                        <TextField
                          fullWidth
                          multiline
                          value={
                            getFieldProps('user').value !== undefined &&
                            getFieldProps('user').value !== null &&
                            getFieldProps('user').value !== ''
                              ? `${getFieldProps('user').value.name}\n${
                                  getFieldProps('user').value.phoneNumber
                                }\n${getFieldProps('user').value.shippingAddress}\n${
                                  getFieldProps('user').value.gstNumber
                                }`
                              : ``
                          }
                          label={`${type === 'CUSTOMER' ? 'Customer' : 'Supplier'} details`}
                          error={Boolean(touched.user && errors.user)}
                          helperText={touched.user && errors.user}
                          disabled
                        />
                      </Stack>
                      <Stack spacing={2} width="50%">
                        <TextField
                          fullWidth
                          label="To"
                          {...getFieldProps('to')}
                          error={Boolean(touched.to && errors.to)}
                          helperText={touched.to && errors.to}
                          type="date"
                        />
                      </Stack>
                    </Stack>
                  </>
                )}
                {(type === 'SALES' || type === 'PURCHASE') && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Stack spacing={2} width="50%">
                      <TextField
                        label="From"
                        fullWidth
                        {...getFieldProps('from')}
                        error={Boolean(touched.from && errors.from)}
                        helperText={touched.from && errors.from}
                        type="date"
                      />
                    </Stack>
                    <Stack spacing={2} width="50%">
                      <TextField
                        label="To"
                        fullWidth
                        {...getFieldProps('to')}
                        error={Boolean(touched.to && errors.to)}
                        helperText={touched.to && errors.to}
                        type="date"
                      />
                    </Stack>
                  </Stack>
                )}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Generate {title}
                </LoadingButton>
                <Divider />
              </Stack>
            </Form>
          </FormikProvider>
          {userReport !== '' && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
                mb={2}
              >
                <Typography variant="h4" gutterBottom />
                <Button
                  variant="contained"
                  onClick={handlePrint}
                  startIcon={<Icon icon={cloudDownload} />}
                >
                  Print as PDF
                </Button>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
                pt={5}
                ref={componentRef}
              >
                {parse(userReport)}
              </Stack>
            </>
          )}
        </Container>
      </Scrollbar>
    </Page>
  );
}
