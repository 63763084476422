export const firebaseConfig = {
  apiKey: 'AIzaSyAeQYkoE982VtbYDgbtFIVQ-1be7ftewmc',
  authDomain: 'mobile-pos-e96ca.firebaseapp.com',
  databaseURL: 'https://mobile-pos-e96ca-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'mobile-pos-e96ca',
  storageBucket: 'mobile-pos-e96ca.appspot.com',
  messagingSenderId: '1058671248811',
  appId: '1:1058671248811:web:c4ae24e138460f8a943eee',
  measurementId: 'G-N06GLDKQG4'
};
// {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APPID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
