import { toTwoDecimalPlaces } from '../utils/FloatUtils';

const renderItemRow = (item, index) => `
        <tr>
            <th colspan="2">${index + 1}</th>
            <th colspan="2">${item.title}</th>
            <th colspan="2">${item.quantity}</th>
            <th colspan="2">${toTwoDecimalPlaces(item.mrp)}</th>
            <th colspan="2">${item.unit.label}</th>
            <th colspan="2">${item.tax.label}</th>
            <th colspan="2">${item.hsncode}</th>
      </tr>
    `;

export const StockReportTemplate = (items, currencySymbol) => {
  const title = 'Stock Report';

  const rows = items;
  let tableRows;
  if (rows.length > 0) {
    tableRows = rows
      .map((row, index) => renderItemRow(row, index))
      .reduce((a, b) => `${a}\n${b}`, '');
  } else {
    tableRows = '<tr><td></td><td colspan="12" style="text-align: center;">No Data found</td></tr>';
  }

  return `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8"/>
    <meta name="viewport" content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0, user-scalable=1"/>
    <title>${title}</title>
    <style>
      html {
        margin: auto;
        text-align: left;
      }

      .alnright { text-align: right; }
      .alnleft { text-align: left; }
      .alncenter { text-align: center; }

      p {
        margin: 5px;
      }

      table {
        width: 100%; padding: 10px;
      }
    </style>
</head>
<body>
    <div class="alncenter" id="title">
      <h2>${title}</h2>
    </div>
    <hr />
    <div id="summary-table">
      <table>
        <thead>
          <tr>
            <th colspan="2">S.No</th>
            <th colspan="2">Item Name</th>
            <th colspan="2">Quantity</th>
            <th colspan="2">Price/Unit (${currencySymbol.label})</th>
            <th colspan="2">Unit</th>
            <th colspan="2">Tax</th>
            <th colspan="2">HSN Code</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    </div>
  </body>
</html>
    `;
};
