import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const Customer = () => {
  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
    { id: 'gstNumber', label: 'GST Number', alignRight: false },
    { id: 'shippingAddress', label: 'Shipping Address', alignRight: false },
    { id: '' }
  ];

  const { customers } = useSelector((state) => state.customers);

  return <User title="Customer" tableHeader={TABLE_HEAD} data={customers} type="CUSTOMER" />;
};

export default Customer;
