export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const REFRESH_CUSTOMERS = 'REFRESH_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

export const fetchCustomers = () => ({ type: FETCH_CUSTOMERS });

export const addCustomer = (data) => ({ type: ADD_CUSTOMER, data });

export const updateCustomer = (data) => ({ type: UPDATE_CUSTOMER, data });
