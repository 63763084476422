import moment from 'moment';
import { UPDATE_COMPANY_PROFILE, UPDATE_PERSONAL_PROFILE } from './settingsActions';
import * as syncActions from './syncActions';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FETCH_AUTH_DATA = 'FETCH_AUTH_DATA';
export const AUTHENTICATE = 'AUTHENTICATE';
let timer;
let syncTimer;

const API_KEY = 'AIzaSyAeQYkoE982VtbYDgbtFIVQ-1be7ftewmc';
const LOGIN_URL = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${API_KEY}`;
export const USERS_URL =
  'https://mobile-pos-e96ca-default-rtdb.asia-southeast1.firebasedatabase.app/users/';

export const fetchAuthData = () => ({ type: FETCH_AUTH_DATA });

export const authenticate = (userId, userToken, expiryTime) => (dispatch) => {
  dispatch(setLogoutTimer(expiryTime));
  dispatch({
    type: AUTHENTICATE,
    userId,
    userToken,
    expirationTime: expiryTime
  });
};

const clearLogoutTimer = () => {
  if (timer) clearTimeout(timer);
};

const setLogoutTimer = (expirationTime) => (dispatch) => {
  timer = setTimeout(() => {
    dispatch(logout());
  }, expirationTime);
};

const clearSyncTimer = () => {
  if (syncTimer) clearInterval(syncTimer);
};

const setSyncTimer = (interval) => (dispatch) => {
  syncTimer = setInterval(() => {
    syncDataToCloud(dispatch);
  }, interval);
};

export const logout = () => {
  clearLogoutTimer();
  return { type: LOGOUT };
};

export const login = (email, password) => async (dispatch) => {
  const response = await fetch(LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
      returnSecureToken: true
    })
  });

  if (!response.ok) {
    const message = 'Invalid email/password. \nPlease check your credentials';
    throw new Error(message);
  }

  const resData = await response.json();

  // const expirationDate = new Date(new Date().getTime() + parseInt(resData.expiresIn) * 1000);
  const expirationDate = moment().add(1, 'days').set('hour', 6).set('minute', 0).toDate();
  dispatch({
    type: LOGIN,
    token: resData.idToken,
    userId: resData.localId,
    expiryDate: expirationDate.toISOString()
  });

  // dispatch(syncActions.createUserProfile());

  const userDetails = await fetch(`${USERS_URL + resData.localId}.json?auth=${resData.idToken}`, {
    method: 'GET'
  });

  if (!userDetails.ok) {
    const message = 'Error fetching user details, please try again later';
    throw new Error(message);
  }

  const userData = await userDetails.json();
  // console.log(`User Data:${JSON.stringify(userData)}`);

  dispatch({ type: UPDATE_PERSONAL_PROFILE, data: userData.profile });
  dispatch({ type: UPDATE_COMPANY_PROFILE, data: userData.companyProfile });

  // syncDataToCloud(dispatch);
  const syncInterval = 60 * 1000; // 1 min Interval in milli seconds
  // dispatch(setSyncTimer(syncInterval));
};

const syncDataToCloud = (dispatch) => {
  console.log('Inside sync');
  dispatch(syncActions.syncSettings());
  dispatch(syncActions.syncStock());
  dispatch(syncActions.syncCustomers());
  dispatch(syncActions.syncSuppliers());
  dispatch(syncActions.syncInvoices());
  dispatch(syncActions.syncPurchaseOrders());
  dispatch(syncActions.syncReceipts());
  dispatch(syncActions.syncPayments());
};
