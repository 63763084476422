// material
import { Autocomplete, Container, Stack, TextField, Typography } from '@material-ui/core';
// components
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as stockActions from '../store/actions/stockActions';
import Page from '../components/Page';
import { TAX_RATES, UNITS } from '../config/data/init';
import TaxRate from '../models/TaxRate';
import Unit from '../models/Unit';
//
// ----------------------------------------------------------------------

export default function AddInvoiceItem(props) {
  const { id } = useParams();
  const { type, userType } = props;
  const [stock, setStock] = useState({
    title: '',
    quantity: '',
    mrp: '',
    hsncode: '',
    unit: '',
    tax: ''
  });
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.stock.stock);

  useEffect(() => {
    setTitle(renderTitle(type));
  }, [type]);

  const populateUserDetails = () => {
    let item = {};
    if (userType === 'STOCK') {
      item = items.find((item) => item.id.toString() === id.toString());
    }
    setStock({
      title: item && item.title,
      quantity: item && item.quantity,
      mrp: item && item.mrp,
      hsncode: item && item.hsncode,
      unit: item && item.unit,
      tax: item && item.tax
    });
    // console.log(`Item:${JSON.stringify(item)}`);
  };

  const renderTitle = (type) => {
    let title = '';
    const user = userType === 'STOCK' ? 'Stock Item' : '';
    switch (type) {
      case 'ADD':
        title = `Add ${user}`;
        break;
      case 'EDIT':
        title = `Edit ${user}`;
        populateUserDetails();
        break;
      default:
        title = `${user} Details`;
        break;
    }
    return title;
  };
  const navigate = useNavigate();

  const UserSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Item Name required'),
    quantity: Yup.string().required('Quantity is required'),
    mrp: Yup.string().required('MRP is required'),
    hsncode: Yup.string().required('HSN Code is required'),
    unit: Yup.object().required('Unit is required'),
    tax: Yup.object().required('Tax is required')
  });

  const formik = useFormik({
    initialValues: stock,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: () => {
      const updatedItem = {
        id: id || getFieldProps('title').value,
        title: getFieldProps('title').value,
        quantity: getFieldProps('quantity').value,
        mrp: getFieldProps('mrp').value,
        hsncode: getFieldProps('hsncode').value,
        unit: getFieldProps('unit').value,
        tax: getFieldProps('tax').value
      };
      // console.log(JSON.stringify(updatedItem));
      if (type === 'ADD') {
        if (userType === 'STOCK') {
          dispatch(stockActions.addStockItem(updatedItem));
        }
      } else if (type === 'EDIT') {
        if (userType === 'STOCK') {
          dispatch(stockActions.updateStockItem(updatedItem));
        }
      }
      if (type === 'EDIT') {
        navigate('./../../', { replace: true });
      } else {
        navigate('./../', { replace: true });
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;
  return (
    <Page title={`Dashboard: ${title} | Quick Bill`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Item Name"
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Quantity"
                  {...getFieldProps('quantity')}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                  type="number"
                />

                <TextField
                  fullWidth
                  label="MRP"
                  {...getFieldProps('mrp')}
                  error={Boolean(touched.mrp && errors.mrp)}
                  helperText={touched.mrp && errors.mrp}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Autocomplete
                  value={
                    getFieldProps('unit').value
                      ? getFieldProps('unit').value
                      : new Unit('0', 'None', 'None')
                  }
                  options={UNITS}
                  onChange={(event, value) => setFieldValue('unit', value)}
                  getOptionLabel={(option) => option.label}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      label="Unit"
                      {...params}
                      {...getFieldProps('unit')}
                      error={Boolean(touched.unit && errors.unit)}
                      helperText={touched.unit && errors.unit}
                    />
                  )}
                />

                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  value={
                    getFieldProps('tax').value
                      ? getFieldProps('tax').value
                      : new TaxRate('0', 'None', 'None')
                  }
                  options={TAX_RATES}
                  onChange={(event, value) => setFieldValue('tax', value)}
                  getOptionLabel={(option) => option.label}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      label="Tax Rate"
                      {...params}
                      {...getFieldProps('tax')}
                      error={Boolean(touched.tax && errors.tax)}
                      helperText={touched.tax && errors.tax}
                    />
                  )}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="HSN Code"
                  {...getFieldProps('hsncode')}
                  error={Boolean(touched.hsncode && errors.hsncode)}
                  helperText={touched.hsncode && errors.hsncode}
                />
              </Stack>

              {(type === 'ADD' || type === 'EDIT') && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Details
                </LoadingButton>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
