import React, { useState } from 'react';
import { Box, Container, Stack, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import { LoadingButton } from '@material-ui/lab';
import { Icon } from '@iconify/react';
import checkmarkCircle from '@iconify/icons-eva/checkmark-circle-2-fill';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import * as stockActions from '../store/actions/stockActions';
import StockItem from '../models/Stock';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex'
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em'
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10
  },
  zoneHover: {
    borderColor: GREY_DIM
  },
  default: {
    borderColor: GREY
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23
  }
};

const ImportData = (props) => {
  const { type } = props;

  const { CSVReader } = useCSVReader();
  const [results, setResults] = useState([]);
  const [importSuccessful, setImportSuccessful] = useState(false);
  const [zoneHover, setZoneHover] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  const { currentStockItemId } = useSelector((state) => state.stock.stock);

  const dispatch = useDispatch();
  const updateStockItem = (item) => dispatch(stockActions.updateStockItem(item));

  const handleImportStock = () => {
    let curStockItemId = currentStockItemId || 0;
    const headers = results[0];
    let rows = results.slice(1);
    // eslint-disable-next-line array-callback-return
    rows = rows.map((item) => {
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < headers.length; i++) {
        obj[headers[i]] = item[i];
      }
      curStockItemId += 1;
      obj.id = curStockItemId;
      return obj;
    });
    // console.log(rows);
    const stockItems = rows.map((item) => StockItem.createStockItem(item));
    // console.log(`Stock Items:${JSON.stringify(stockItems)}`);
    stockItems.forEach((stockItem) => updateStockItem(stockItem));
    setImportSuccessful(true);
    setIsDisabled(true);
    setResults([]);
  };

  const stockSampleFileLink =
    'https://drive.google.com/file/d/1_BzXBcFGCcjKA7Vs2LYtgXOBgzBom5Q8/view?usp=sharing';
  const link = type === 'STOCK' ? stockSampleFileLink : '';

  return (
    <Page title={`Dashboard: Import ${type} | Quick Bill`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Import {type}
          </Typography>
        </Stack>
        <Stack>
          <CSVReader
            onUploadAccepted={(results) => {
              // console.log('---------------------------');
              // console.log(results);
              // console.log('---------------------------');
              setZoneHover(false);
              setIsDisabled(false);
              setResults(results.data);
            }}
            onDragOver={(event) => {
              event.preventDefault();
              setZoneHover(true);
            }}
            onDragLeave={(event) => {
              event.preventDefault();
              setZoneHover(false);
            }}
          >
            {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
              <>
                <div
                  {...getRootProps()}
                  style={{ ...styles.zone, ...(zoneHover && styles.zoneHover) }}
                >
                  {acceptedFile ? (
                    <>
                      <div style={styles.file}>
                        <div style={styles.info}>
                          <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                          <span style={styles.name}>{acceptedFile.name}</span>
                        </div>
                        <div style={styles.progressBar}>
                          <ProgressBar />
                        </div>
                        <div
                          {...getRemoveFileProps()}
                          style={styles.remove}
                          onMouseOver={(event) => {
                            event.preventDefault();
                            setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                          }}
                          onMouseOut={(event) => {
                            event.preventDefault();
                            setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                          }}
                          onBlur={() => {}}
                          onFocus={() => {}}
                        >
                          <Remove color={removeHoverColor} />
                        </div>
                      </div>
                    </>
                  ) : (
                    'Drop CSV file here or click to upload'
                  )}
                </div>
              </>
            )}
          </CSVReader>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              disabled={isDisabled}
              onClick={handleImportStock}
            >
              Import Stock
            </LoadingButton>
          </Box>
        </Stack>
        <Stack mt={5}>
          <Typography variant="h6" gutterBottom>
            Download sample file from here :
            <a href={link} target="_blank" rel="noopener noreferrer">
              {type} Sample CSV
            </a>
          </Typography>
        </Stack>

        {importSuccessful && (
          <Stack>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom sx={{ px: 5, mt: 5 }}>
                Stock imported Successfully
              </Typography>
              <Icon icon={checkmarkCircle} width={150} height={150} color="green" />
            </Box>
          </Stack>
        )}
      </Container>
    </Page>
  );
};

export default ImportData;
