import faker from 'faker';
import PropTypes from 'prop-types';
// material
import { Card, Typography, CardHeader, CardContent } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot
} from '@material-ui/lab';
// utils
import moment from 'moment';
import { useSelector } from 'react-redux';
import { fDate } from '../../../utils/formatTime';
import { supportedDateFormats } from '../../../utils/DateUtils';

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor:
              (type === 'order1' && 'primary.main') ||
              (type === 'order2' && 'success.main') ||
              (type === 'order3' && 'info.main') ||
              (type === 'order4' && 'warning.main') ||
              'error.main'
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDate(time)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default function AppOrderTimeline() {
  const { invoices } = useSelector((state) => state.invoices);

  const prevFiveDays = {};
  prevFiveDays[fDate(moment())] = { orders: 0, total: 0 };
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < 5; i++) {
    prevFiveDays[fDate(moment().add(-i, 'day'))] = { orders: 0, total: 0 };
  }

  const prevFiveDaysInvoices = invoices
    .filter(
      (inv) =>
        moment(inv.createdAt, supportedDateFormats).isAfter(moment().add(-5, 'days')) &&
        moment(inv.createdAt, supportedDateFormats).isBefore(moment().add(1, 'day'))
    )
    .map((inv) => ({ date: inv.createdAt, amount: inv.total }));

  prevFiveDaysInvoices.forEach((inv) => {
    prevFiveDays[fDate(moment(inv.date, supportedDateFormats))].total += parseFloat(inv.amount);
    prevFiveDays[fDate(moment(inv.date, supportedDateFormats))].orders += 1;
  });

  const TIMELINES = [];
  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const key in prevFiveDays) {
    const item = {
      title: `${prevFiveDays[key].orders} invoices, ₹ ${prevFiveDays[key].total}`,
      time: key,
      type: 'order1'
    };
    TIMELINES.push(item);
  }
  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      <CardHeader title="Order Timeline" />
      <CardContent>
        <Timeline>
          {TIMELINES.map((item, index) => (
            <OrderItem key={item.time} item={item} isLast={index === TIMELINES.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}
