export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const REFRESH_SUPPLIERS = 'REFRESH_SUPPLIERS';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';

export const fetchSuppliers = () => ({ type: FETCH_SUPPLIERS });

export const addSupplier = (data) => ({ type: ADD_SUPPLIER, data });

export const updateSupplier = (data) => ({ type: UPDATE_SUPPLIER, data });

export const refreshSuppliers = () => ({ type: REFRESH_SUPPLIERS });
