import {
  ADD_SUPPLIER,
  FETCH_SUPPLIERS,
  REFRESH_SUPPLIERS,
  UPDATE_SUPPLIER
} from '../actions/suppliersActions';
import { SUPPLIERS } from '../../config/data/init';

const initialState = {
  suppliers: [...SUPPLIERS]
};

const sortItems = (items) =>
  items.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUPPLIERS:
      return {
        ...state,
        suppliers: sortItems(state.suppliers)
      };
    case REFRESH_SUPPLIERS:
      return state;
    case ADD_SUPPLIER: {
      const supplier = action.data;
      let updatedSuppliers = [...state.suppliers];
      updatedSuppliers = updatedSuppliers.concat(supplier);
      return {
        ...state,
        suppliers: sortItems(updatedSuppliers)
      };
    }
    case UPDATE_SUPPLIER: {
      const updatedSupplier = action.data;
      let finalUpdatedSuppliers = [...state.suppliers];
      finalUpdatedSuppliers = finalUpdatedSuppliers.filter(
        (item) => item.id !== updatedSupplier.id
      );
      finalUpdatedSuppliers = finalUpdatedSuppliers.concat(updatedSupplier);
      return {
        ...state,
        suppliers: sortItems(finalUpdatedSuppliers)
      };
    }
    default:
      return state;
  }
};

export default suppliersReducer;
