import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const Payment = () => {
  const TABLE_HEAD = [
    { id: 'paymentId', label: 'Payment Id', alignRight: false },
    { id: 'createdAt', label: 'Date', alignRight: false },
    { id: 'supplierId', label: 'Supplier', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'paymentType', label: 'Payment Type', alignRight: false },
    { id: '' }
  ];

  const { payments } = useSelector((state) => state.payments);
  const { suppliers } = useSelector((state) => state.suppliers);

  const getUserDetails = (id) =>
    suppliers.find((supplier) => supplier.id.toString() === id.toString());

  const updatedPayments = payments.map((order) => ({
    ...order,
    supplierId: getUserDetails(order.supplierId).name
  }));

  return <User title="Payment" tableHeader={TABLE_HEAD} data={updatedPayments} type="PAYMENT" />;
};

export default Payment;
