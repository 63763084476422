class Payment {
  constructor(
    id,
    paymentId,
    supplierId,
    purchaseOrderIds,
    amount,
    paymentType,
    createdAt,
    updatedAt,
    bankAccount,
    notes
  ) {
    this.id = id;
    this.paymentId = paymentId;
    this.supplierId = supplierId;
    this.purchaseOrderIds = purchaseOrderIds;
    this.amount = amount;
    this.paymentType = paymentType;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.bankAccount = bankAccount;
    this.notes = notes;
  }
}

export default Payment;
