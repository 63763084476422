class Customer {
  constructor(id, name, phoneNumber, shippingAddress, gstNumber) {
    this.id = id;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.shippingAddress = shippingAddress;
    this.gstNumber = gstNumber;
  }
}

export default Customer;
