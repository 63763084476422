// material
import { Button, Container, Stack, Typography } from '@material-ui/core';
// components
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
//
import BankAccountList from '../layouts/dashboard/bankAccount/BankAccountList';

// ----------------------------------------------------------------------

export default function BankAccounts() {
  const { bankAccounts } = useSelector((state) => state.settings.settings);
  return (
    <Page title="Dashboard: Bank Accounts | Quick Bill">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Bank Accounts
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Icon icon={plusFill} />}
          >
            New Bank Account
          </Button>
        </Stack>

        <BankAccountList products={bankAccounts} />
      </Container>
    </Page>
  );
}
