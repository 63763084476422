import {
  FETCH_PURCHASE_ORDERS,
  ADD_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  REFRESH_PURCHASE_ORDERS
} from '../actions/purchaseActions';
import PurchaseOrder from '../../models/PurchaseOrder';
import { formatDateWithCurrentTime } from '../../utils/DateUtils';

const initialState = {
  purchaseOrders: [],
  currentPurchaseOrderId: 1,
  prefix: 'PO-'
};

const sortItems = (items) => items.sort((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt));

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: sortItems(state.purchaseOrders)
      };
    case REFRESH_PURCHASE_ORDERS:
      return state;
    case ADD_PURCHASE_ORDER: {
      const { data } = action;
      const { currentPurchaseOrderId } = state;
      const purchaseOrder = new PurchaseOrder(
        currentPurchaseOrderId.toLocaleString(),
        data.orderId,
        data.supplierId,
        data.supplierInvoiceId,
        data.items,
        data.subTotal,
        data.taxTotal,
        data.itemDiscountTotal,
        data.overallDiscountTotal,
        data.shippingCharges,
        data.roundOff,
        data.total,
        data.total,
        data.paymentType,
        formatDateWithCurrentTime(data.createdAt),
        formatDateWithCurrentTime(data.createdAt),
        data.paymentStatus,
        data.personThrough
      );
      let updatedPurchaseOrders = [...state.purchaseOrders];
      updatedPurchaseOrders = updatedPurchaseOrders.concat(purchaseOrder);
      return {
        ...state,
        currentPurchaseOrderId: currentPurchaseOrderId + 1,
        purchaseOrders: sortItems(updatedPurchaseOrders)
      };
    }
    case UPDATE_PURCHASE_ORDER: {
      const purchaseOrderData = action.data;
      const updatedPurchaseOrder = new PurchaseOrder(
        purchaseOrderData.id,
        purchaseOrderData.orderId,
        purchaseOrderData.supplierId,
        purchaseOrderData.supplierInvoiceId,
        purchaseOrderData.items,
        purchaseOrderData.subTotal,
        purchaseOrderData.taxTotal,
        purchaseOrderData.itemDiscountTotal,
        purchaseOrderData.overallDiscountTotal,
        purchaseOrderData.shippingCharges,
        purchaseOrderData.roundOff,
        purchaseOrderData.total,
        purchaseOrderData.balance,
        purchaseOrderData.paymentType,
        purchaseOrderData.createdAt,
        formatDateWithCurrentTime(new Date()),
        purchaseOrderData.paymentStatus,
        purchaseOrderData.personThrough
      );
      let finalPurchasedOrders = [...state.purchaseOrders];
      finalPurchasedOrders = finalPurchasedOrders.filter(
        (item) => item.id !== updatedPurchaseOrder.id
      );
      finalPurchasedOrders = finalPurchasedOrders.concat(updatedPurchaseOrder);
      return {
        ...state,
        purchaseOrders: sortItems(finalPurchasedOrders)
      };
    }
    default:
      return state;
  }
};

export default purchaseReducer;
