import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const Receipt = () => {
  const TABLE_HEAD = [
    { id: 'receiptId', label: 'Receipt Id', alignRight: false },
    { id: 'createdAt', label: 'Date', alignRight: false },
    { id: 'customerId', label: 'Customer', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'paymentType', label: 'Payment Type', alignRight: false },
    { id: '' }
  ];

  const { receipts } = useSelector((state) => state.receipts);
  const { customers } = useSelector((state) => state.customers);

  const getUserDetails = (id) =>
    customers.find((customer) => customer.id.toString() === id.toString());

  const updatedReceipts = receipts.map((receipt) => ({
    ...receipt,
    customerId: getUserDetails(receipt.customerId).name
  }));

  return <User title="Receipt" tableHeader={TABLE_HEAD} data={updatedReceipts} type="RECEIPT" />;
};

export default Receipt;
