import TaxRate from './TaxRate';
import Unit from './Unit';

class StockItem {
  constructor(id, title, description, quantity, unit, mrp, tax, hsncode, expiryDate, isExpirable) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.quantity = quantity;
    this.unit = unit;
    this.mrp = mrp;
    this.tax = tax;
    this.hsncode = hsncode;
    this.expiryDate = expiryDate;
    this.isExpirable = isExpirable;
  }

  static createStockItem(item) {
    return new StockItem(
      item.id,
      item.title,
      item.description || '',
      item.quantity,
      Unit.withUnit(item.unit),
      item.mrp,
      TaxRate.withTax(item.tax),
      item.hsncode
    );
  }
}

export default StockItem;
