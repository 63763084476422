import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// utils
//

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

BankAccountCard.propTypes = {
  product: PropTypes.object
};

export default function BankAccountCard({ product }) {
  const { id, accountName, accountNumber, ifscCode, branchName } = product;

  return (
    <Card>
      <Link to={`view/${id}`} color="inherit" underline="none" component={RouterLink}>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          <ProductImgStyle alt={accountName} src="/static/bank-icon.jpeg" />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="subtitle1" noWrap>
            {accountName}
          </Typography>

          <Typography variant="subtitle1">
            <Typography component="span" variant="body1">
              {accountNumber}
            </Typography>
          </Typography>

          <Typography variant="subtitle1">
            <Typography component="span" variant="body1">
              {branchName}
            </Typography>
          </Typography>

          <Typography variant="subtitle1">
            <Typography component="span" variant="body1">
              {ifscCode}
            </Typography>
          </Typography>
        </Stack>
      </Link>
    </Card>
  );
}
