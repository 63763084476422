// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import authReducer from './store/reducers/auth';
import settingsReducer from './store/reducers/settingsReducer';
import notificationReducer from './store/reducers/notificationReducer';
import stockReducer from './store/reducers/stockReducer';
import customersReducer from './store/reducers/customersReducer';
import suppliersReducer from './store/reducers/suppliersReducer';
import invoicesReducer from './store/reducers/invoicesReducer';
import purchaseReducer from './store/reducers/purchaseReducer';
import receiptsReducer from './store/reducers/receiptsReducer';
import paymentsReducer from './store/reducers/paymentsReducer';

// ----------------------------------------------------------------------

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  notifications: notificationReducer,
  stock: stockReducer,
  customers: customersReducer,
  suppliers: suppliersReducer,
  invoices: invoicesReducer,
  purchaseOrders: purchaseReducer,
  receipts: receiptsReducer,
  payments: paymentsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));

const persiStore = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persiStore} loading={null}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
