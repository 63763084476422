import Customer from '../../models/Customer';
import Supplier from '../../models/Supplier';
import Unit from '../../models/Unit';
import Payment from '../../models/Payment';
import Receipt from '../../models/Receipt';
import Invoice from '../../models/Invoice';
import PurchaseOrder from '../../models/PurchaseOrder';
import Stock from '../../models/Stock';
import BankAccount from '../../models/BankAccount';
import PaymentStatus from '../../models/PaymentStatus';
import PaymentType from '../../models/PaymentType';
import TaxRate from '../../models/TaxRate';

export const MAX_CASH_PER_BILL = 10000;

export const PRINTING_ROLL_CONFIG = [
  { id: 1, label: '59mm', value: '59mm' },
  { id: 2, label: '79mm', value: '79mm' }
];

export const DEFAULT_UNIT_NONE = new Unit('0', 'None', 'None');
export const DEFAULT_TAX_NONE = new TaxRate('0', 'None', 'None');
export const DEFAULT_CUSTOMER_NONE = new Customer('0', 'None', 'None', 'None', 'None');
export const DEFUALT_PAYMENT_TYPE_NONE = new PaymentType('0', 'None', 'None');
export const DEFUALT_BANK_ACCOUNT_NONE = new BankAccount(
  '0',
  'None',
  'None',
  'None',
  'None',
  'None'
);

export const CUSTOMERS = [
  new Customer('9999999999', 'Walk-in-Client', '9999999999', 'Shipping Address', 'XXABCDEXXXXFXZ')
];

export const SUPPLIERS = [
  new Supplier('9999999999', 'Walk-in-Supplier', '9999999999', 'Shipping Address', 'XXABCDEXXXXFXZ')
];

export const STOCK_ITEMS = [
  new Stock(
    'Books Books Books Books Books Books',
    'Books Books Books Books Books Books',
    20,
    'PCS',
    10,
    '5%',
    'HSN000123',
    new Date().toLocaleString(),
    false
  )
];

export const PAYMENTS = [
  new Payment(
    '1',
    'PYMT-1',
    'SUP-1',
    ['PO-1', 'PO-2'],
    1000,
    'CASH',
    new Date().toLocaleString(),
    new Date().toLocaleString(),
    'HDFC245',
    'UPI Transfer'
  )
];

export const RECEIPTS = [
  new Receipt(
    '1',
    'RCPT-1',
    'CUST-1',
    ['INV-1', 'INV-2'],
    1000,
    'CASH',
    new Date().toLocaleString(),
    new Date().toLocaleString(),
    'HDFC245',
    'UPI Transfer'
  )
];

export const INVOICES = [
  new Invoice(
    '1',
    'INV-1',
    'CUST-1',
    ['ITEM-1', 'ITEM-2'],
    1000,
    180,
    180,
    0,
    180,
    0,
    'CASH',
    new Date().toLocaleString(),
    new Date().toLocaleString(),
    'PAID',
    'Pick Up'
  )
];

export const PURCHASE_ORDERS = [
  new PurchaseOrder(
    '1',
    'PO-1',
    'CUST-1',
    ['ITEM-1', 'ITEM-2'],
    1000,
    180,
    180,
    0,
    180,
    0,
    'CASH',
    new Date().toLocaleString(),
    new Date().toLocaleString(),
    'PAID',
    'Pick Up'
  )
];

export const BANK_ACCOUNTS = [
  new BankAccount('1', 'None', '1234567890', 'XYZ Bank', 'XYZ001234', 'XYZ Branch'),
  new BankAccount(
    '2',
    'SBI Account',
    '1234567890',
    'State Bank of India',
    'SBIN00XXXXX',
    'SBI Some Branch'
  )
];

export const SUPPORTED_BANKS = [
  { label: 'SBI', id: 'SBI' },
  { label: 'HDFC', id: 'HDFC' },
  { label: 'ICICI', id: 'ICICI' },
  { label: 'AXIS', id: 'AXIS' },
  { label: 'Kotak Mahindra', id: 'KOTAK' },
  { label: 'Indian Bank', id: 'INDIAN_BANK' }
];

export const TAX_RATES = [
  new TaxRate(1, '0%', 0.0),
  new TaxRate(2, '0.25%', 0.25),
  new TaxRate(3, '3%', 3.0),
  new TaxRate(4, '5%', 5.0),
  new TaxRate(5, '12%', 12.0),
  new TaxRate(6, '18%', 18.0),
  new TaxRate(7, '28%', 28.0)
];

export const UNITS = [
  new Unit(1, 'BAG', 'Bag'),
  new Unit(2, 'BDL', 'Bundles'),
  new Unit(3, 'BAL', 'Bale'),
  new Unit(4, 'BKL', 'Buckles'),
  new Unit(5, 'BOU', 'Billions of Units'),
  new Unit(6, 'BOX', 'Box'),
  new Unit(7, 'BTL', 'Bottles'),
  new Unit(8, 'BUN', 'Bunches'),
  new Unit(9, 'CAN', 'Cans'),
  new Unit(10, 'CTN', 'Cartons'),
  new Unit(11, 'DOZ', 'Dozen'),
  new Unit(12, 'DRM', 'Drum'),
  new Unit(13, 'GGR', 'Great Gross'),
  new Unit(14, 'GRS', 'Gross'),
  new Unit(15, 'NOS', 'Numbers'),
  new Unit(16, 'PAC', 'Packs'),
  new Unit(17, 'PCS', 'Pieces'),
  new Unit(18, 'PRS', 'Pairs'),
  new Unit(19, 'ROL', 'Rolls'),
  new Unit(20, 'SET', 'Sets'),
  new Unit(21, 'TBS', 'Tablets'),
  new Unit(22, 'TGM', 'Ten Gross'),
  new Unit(23, 'THD', 'Thousands'),
  new Unit(24, 'TUB', 'Tubes'),
  new Unit(25, 'UNT', 'Units'),
  new Unit(26, 'CBM', 'Cubic Meter'),
  new Unit(27, 'CCM', 'Cubic Centimeter'),
  new Unit(28, 'KLR', 'Kilo Litre'),
  new Unit(29, 'MLT', 'Milli Litre'),
  new Unit(30, 'UGS', 'US Gallons'),
  new Unit(31, 'SQF', 'Square Feet'),
  new Unit(32, 'SQM', 'Square Meter'),
  new Unit(33, 'SQY', 'Square Yards'),
  new Unit(34, 'GYD', 'Gross Yards'),
  new Unit(35, 'KME', 'Kilo Meter'),
  new Unit(36, 'MTR', 'Meters'),
  new Unit(37, 'YDS', 'Yards'),
  new Unit(38, 'CMS', 'Centimeter'),
  new Unit(39, 'TON', 'Tonnes'),
  new Unit(40, 'QTL', 'Quintal'),
  new Unit(41, 'GMS', 'Grams'),
  new Unit(42, 'KGS', 'Kilo Grams'),
  new Unit(43, 'RMS', 'Reams'),
  new Unit(43, 'OTH', 'Others')
];

export const CURRENCY_SYMBOLS = [
  { id: 1, label: '₹', value: '₹' },
  { id: 2, label: 'Rs.', value: 'Rs.' },
  { id: 3, label: 'INR', value: 'INR' }
];

export const PAYMENT_STATUSES = {
  DUE: new PaymentStatus(1, 'Due', 'Due'),
  PAID: new PaymentStatus(2, 'Paid', 'Paid')
};

export const PAYMENT_TYPES = {
  CASH: new PaymentType(1, 'Cash', 'Cash'),
  CREDIT: new PaymentType(2, 'Credit', 'Credit'),
  BANK_TRANSFER: new PaymentType(3, 'Bank Transfer', 'Bank Transfer')
};
