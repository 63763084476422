import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  Box
} from '@material-ui/core';
// components
import cloudDownload from '@iconify/icons-eva/cloud-download-fill';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, TYPE) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      switch (TYPE) {
        case 'CUSTOMER':
        case 'SUPPLIER':
          return _user.name.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
        case 'INVOICE':
          return _user.customerId.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
        case 'PURCHASE_ORDER':
          return _user.supplierId.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
        case 'MEASUREMENTUNIT':
        case 'TAX':
          return _user.value.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
        case 'STOCK':
        case 'INVOICE_ITEM':
          return _user.title.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
        default:
          return _user.id.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
      }
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User(props) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const USER_LIST = props.data;
  const TYPE = props.type;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USER_LIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const usersListLength = USER_LIST.length;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersListLength) : 0;

  const filteredUsers = applySortFilter(USER_LIST, getComparator(order, orderBy), filterName, TYPE);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title={`Dashboard: ${props.title} | Quick Bill`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {props.title}
          </Typography>
          <Box>
            {TYPE === 'STOCK' && (
              <Button
                variant="contained"
                startIcon={<Icon icon={cloudDownload} />}
                sx={{ marginX: 1 }}
              >
                Export as CSV
              </Button>
            )}
            <Button
              sx={{ marginX: 1 }}
              variant="contained"
              component={RouterLink}
              to={TYPE === 'INVOICE_ITEM' ? 'item' : 'add'}
              startIcon={<Icon icon={plusFill} />}
            >
              {TYPE === 'INVOICE_ITEM' ? `Add ${props.title}` : `New ${props.title}`}
            </Button>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={props.tableHeader}
                  rowCount={USER_LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id || name}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          {props.tableHeader.map((header) => (
                            <TableCell align="left">
                              {row[header.id] && row[header.id].label
                                ? row[header.id].label
                                : row[header.id]}
                            </TableCell>
                          ))}

                          <TableCell align="right">
                            {TYPE === 'INVOICE' ||
                            TYPE === 'PURCHASE_ORDER' ||
                            TYPE === 'RECEIPT' ||
                            TYPE === 'PAYMENT' ? (
                              <UserMoreMenu id={id} showPrintOption />
                            ) : (
                              <UserMoreMenu id={id} />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {usersListLength > 0 && isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} searchType={TYPE} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {usersListLength === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            Looks like there aren't any items here...
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USER_LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
