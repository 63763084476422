import { ADD_RECEIPT, addReceipt } from './receiptsActions';
import { decreaseStock } from './stockActions';
import { PAYMENT_TYPES } from '../../config/data/init';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const REFRESH_INVOICES = 'REFRESH_INVOICES';
export const ADD_INVOICE = 'ADD_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const addInvoice = (data) => (dispatch, getState) => {
  dispatch({ type: ADD_INVOICE, data });
  const { invoiceId } = data;
  const invoices = getState().invoices.invoices.filter((inv) => inv.invoiceId === invoiceId);
  // console.log(data);
  if (data.paymentType.value !== PAYMENT_TYPES.CREDIT.value) {
    const { currentReceiptId } = getState().receipts;
    const { receiptPrefix } = getState().settings.settings.billing;
    const receiptId = receiptPrefix.concat(currentReceiptId);
    const receiptData = {
      receiptId,
      customerId: data.customerId,
      invoiceIds: invoices,
      amount: data.total,
      paymentType: data.paymentType,
      createdAt: data.createdAt,
      bankAccount: data.bankAccount,
      notes: data.notes
    };
    dispatch(addReceipt(receiptData));
  }
  dispatch(decreaseStock(data.items));
};

export const updateInvoice = (data) => (dispatch, getState) => {
  dispatch({ type: UPDATE_INVOICE, data });
  const { invoiceId } = data;
  const invoices = getState().invoices.invoices.filter((inv) => inv.invoiceId === invoiceId);
  if (data.paymentType.value !== PAYMENT_TYPES.CREDIT.value) {
    const { currentReceiptId } = getState().receipts;
    const { receiptPrefix } = getState().settings.settings.billing;
    const receiptId = receiptPrefix.concat(currentReceiptId);
    const receiptData = {
      receiptId,
      customerId: data.customerId,
      invoiceIds: invoices,
      amount: data.total,
      paymentType: data.paymentType,
      createdAt: data.createdAt,
      bankAccount: data.bankAccount,
      notes: data.notes
    };
    dispatch({ type: ADD_RECEIPT, data: receiptData });
  }
};
