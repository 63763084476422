// material
import { Container, Stack, TextField, Typography } from '@material-ui/core';
// components
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as customerActions from '../store/actions/customersActions';
import * as suppliersActions from '../store/actions/suppliersActions';
import Page from '../components/Page';
import Customer from '../models/Customer';
import Supplier from '../models/Supplier';
//
// ----------------------------------------------------------------------

export default function AddUser(props) {
  const { id } = useParams();
  const { type, userType } = props;
  const [user, setUser] = useState({
    fullName: '',
    phoneNumber: '',
    gstNumber: '',
    shippingAddress: ''
  });
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();
  const { suppliers } = useSelector((state) => state.suppliers);
  const { customers } = useSelector((state) => state.customers);

  useEffect(() => {
    setTitle(renderTitle(type));
  }, [type]);

  const populateUserDetails = () => {
    let user = {};
    if (userType === 'CUSTOMER') {
      user = customers.find((customer) => customer.id.toString() === id.toString());
    } else {
      user = suppliers.find((supplier) => supplier.id.toString() === id.toString());
    }
    setUser({
      fullName: user.name,
      phoneNumber: user.phoneNumber,
      gstNumber: user.gstNumber,
      shippingAddress: user.shippingAddress
    });
  };

  const renderTitle = (type) => {
    let title = '';
    const user = userType === 'CUSTOMER' ? 'Customer' : 'Supplier';
    switch (type) {
      case 'ADD':
        title = `Add ${user}`;
        break;
      case 'EDIT':
        title = `Edit ${user}`;
        populateUserDetails();
        break;
      default:
        title = `${user} Details`;
        break;
    }
    return title;
  };
  const navigate = useNavigate();

  const UserSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Full Name required'),
    phoneNumber: Yup.string()
      .min(10, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Phone Number required'),
    gstNumber: Yup.string().required('GST Number is required'),
    shippingAddress: Yup.string().required('Shipping Address is required')
  });

  const formik = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: () => {
      const updatedUser = {
        fullName: getFieldProps('fullName').value,
        phoneNumber: getFieldProps('phoneNumber').value,
        gstNumber: getFieldProps('gstNumber').value,
        shippingAddress: getFieldProps('shippingAddress').value
      };
      if (type === 'ADD') {
        if (userType === 'CUSTOMER') {
          dispatch(
            customerActions.addCustomer(
              new Customer(
                updatedUser.phoneNumber,
                updatedUser.fullName,
                updatedUser.phoneNumber,
                updatedUser.shippingAddress,
                updatedUser.gstNumber
              )
            )
          );
        } else {
          dispatch(
            suppliersActions.addSupplier(
              new Supplier(
                updatedUser.phoneNumber,
                updatedUser.fullName,
                updatedUser.phoneNumber,
                updatedUser.shippingAddress,
                updatedUser.gstNumber
              )
            )
          );
        }
      } else if (type === 'EDIT') {
        if (userType === 'CUSTOMER') {
          dispatch(
            customerActions.updateCustomer(
              new Customer(
                updatedUser.phoneNumber,
                updatedUser.fullName,
                updatedUser.phoneNumber,
                updatedUser.shippingAddress,
                updatedUser.gstNumber
              )
            )
          );
        } else {
          dispatch(
            suppliersActions.updateSupplier(
              new Supplier(
                updatedUser.phoneNumber,
                updatedUser.fullName,
                updatedUser.phoneNumber,
                updatedUser.shippingAddress,
                updatedUser.gstNumber
              )
            )
          );
        }
      }
      if (type === 'EDIT') {
        navigate('./../../', { replace: true });
      } else {
        navigate('./../', { replace: true });
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Page title={`Dashboard: ${title} | Quick Bill`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Full Name"
                {...getFieldProps('fullName')}
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && errors.fullName}
              />

              <TextField
                fullWidth
                label="Phone Number"
                {...getFieldProps('phoneNumber')}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                type="number"
                disabled={type !== 'ADD'}
              />

              <TextField
                fullWidth
                label="GST Number"
                {...getFieldProps('gstNumber')}
                error={Boolean(touched.gstNumber && errors.gstNumber)}
                helperText={touched.gstNumber && errors.gstNumber}
              />

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  multiline
                  label="Shipping Address"
                  {...getFieldProps('shippingAddress')}
                  error={Boolean(touched.shippingAddress && errors.shippingAddress)}
                  helperText={touched.shippingAddress && errors.shippingAddress}
                />
              </Stack>

              {(type === 'ADD' || type === 'EDIT') && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Details
                </LoadingButton>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
