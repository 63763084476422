import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '₹0,0' : '₹0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function convertNumberToWords(amount) {
  const words = [];
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  const aTemp = amount.split('.');
  const number = aTemp[0].split(',').join('');
  const nLen = number.length;
  let wordsStr = '';
  let value;
  if (nLen <= 9) {
    let i;
    const nArr = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    const receivedNArr = [];
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < nLen; i++) {
      receivedNArr[i] = number.substr(i, 1);
    }
    i = 9 - nLen;
    let j = 0;
    // eslint-disable-next-line no-plusplus
    for (; i < 9; i++, j++) {
      nArr[i] = receivedNArr[j];
    }
    i = 0;
    j = 1;
    // eslint-disable-next-line no-plusplus
    for (; i < 9; i++, j++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        if (nArr[i] === 1) {
          nArr[j] = 10 + parseInt(nArr[j], 10);
          nArr[i] = 0;
        }
      }
    }
    value = '';
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < 9; i++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        value = nArr[i] * 10;
      } else {
        value = nArr[i];
      }
      if (value !== 0) {
        wordsStr += `${words[value]} `;
      }
      if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && nArr[i + 1] === 0)) {
        wordsStr += 'Crores ';
      }
      if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && nArr[i + 1] === 0)) {
        wordsStr += 'Lakhs ';
      }
      if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && nArr[i + 1] === 0)) {
        wordsStr += 'Thousand ';
      }
      if (i === 6 && value !== 0 && nArr[i + 1] !== 0 && nArr[i + 2] !== 0) {
        wordsStr += 'Hundred and ';
      } else if (i === 6 && value !== 0) {
        wordsStr += 'Hundred ';
      }
    }
    wordsStr = wordsStr.split('  ').join(' ');
  }
  return wordsStr;
}

export function convertNumberToWordsWithDecimal(n) {
  const nums = n.toString().split('.');
  const whole = convertNumberToWords(nums[0]);
  if (nums.length === 2) {
    const fraction = convertNumberToWords(nums[1]);
    return `${whole}and ${fraction}`;
  }
  return whole;
}
