import {
  FETCH_PAYMENTS,
  ADD_PAYMENT,
  UPDATE_PAYMENT,
  REFRESH_PAYMENTS
} from '../actions/paymentsActions';
import Payment from '../../models/Payment';
import { formatDateWithCurrentTime } from '../../utils/DateUtils';

const initialState = {
  payments: [],
  currentPaymentId: 1,
  prefix: 'PYMT-'
};

const sortItems = (items) => items.sort((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt));

const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return {
        ...state,
        payments: sortItems(state.payments)
      };
    case REFRESH_PAYMENTS:
      return state;
    case ADD_PAYMENT: {
      const { data } = action;
      const { currentPaymentId } = state;
      const receipt = new Payment(
        currentPaymentId.toString(),
        data.paymentId,
        data.supplierId,
        data.purchaseOrderIds,
        data.amount,
        data.paymentType,
        formatDateWithCurrentTime(data.createdAt),
        formatDateWithCurrentTime(data.createdAt),
        data.bankAccount,
        data.notes
      );
      let updatedPayments = [...state.payments];
      updatedPayments = updatedPayments.concat(receipt);
      return {
        ...state,
        currentPaymentId: currentPaymentId + 1,
        payments: sortItems(updatedPayments)
      };
    }
    case UPDATE_PAYMENT: {
      const updatedPaymentData = action.data;
      const updatedPayment = new Payment(
        updatedPaymentData.id,
        updatedPaymentData.paymentId,
        updatedPaymentData.supplierId,
        updatedPaymentData.purchaseOrderIds,
        updatedPaymentData.amount,
        updatedPaymentData.paymentType,
        updatedPaymentData.createdAt,
        formatDateWithCurrentTime(new Date()),
        updatedPaymentData.bankAccount,
        updatedPaymentData.notes
      );
      let finalUpdatedPayments = [...state.payments];
      finalUpdatedPayments = finalUpdatedPayments.filter((item) => item.id !== updatedPayment.id);
      finalUpdatedPayments = finalUpdatedPayments.concat(updatedPayment);
      return {
        ...state,
        payments: sortItems(finalUpdatedPayments)
      };
    }
    default:
      return state;
  }
};

export default paymentsReducer;
