import { UPDATE_INVOICE } from './invoicesActions';
import { sortItemsByDate } from '../../utils/DateUtils';
import { toTwoDecimalPlaces } from '../../utils/FloatUtils';
import { PAYMENT_STATUSES } from '../../config/data/init';

export const FETCH_RECEIPTS = 'FETCH_RECEIPTS';
export const REFRESH_RECEIPTS = 'REFRESH_RECEIPTS';
export const ADD_RECEIPT = 'ADD_RECEIPT';
export const UPDATE_RECEIPT = 'UPDATE_RECEIPT';

export const addReceipt = (data) => (dispatch, getState) => {
  dispatch({ type: ADD_RECEIPT, data });

  const invIds = data.invoiceIds;
  const invoices = sortItemsByDate(
    getState().invoices.invoices.filter((inv) => invIds.includes(inv.id))
  );

  // const invoices = sortItemsByDate(data.invoiceIds);
  let updatedTotal = parseFloat(data.amount);
  let due = 0.0;
  let paymentStatus = PAYMENT_STATUSES.DUE;
  invoices.forEach((inv) => {
    updatedTotal = updatedTotal < 0 ? 0 : updatedTotal;
    due = parseFloat(updatedTotal) - parseFloat(inv.balance);
    updatedTotal = due;
    due = due < 0 ? -due : 0;
    paymentStatus = due === 0 ? PAYMENT_STATUSES.PAID : PAYMENT_STATUSES.DUE;
    dispatch({
      type: UPDATE_INVOICE,
      data: { ...inv, balance: toTwoDecimalPlaces(due), paymentStatus }
    });
  });
};

export const updateReceipt = (data) => ({ type: UPDATE_RECEIPT, data });
