import { UNITS } from '../config/data/init';

export default class Unit {
  constructor(id, label, value) {
    this.id = id;
    this.label = label;
    this.value = value;
  }

  static withUnit(label) {
    const unit = UNITS.filter((unit) => unit.label.toString() === label.toString());
    if (unit.length > 0) return unit[0];
    return new Unit(0, 'None', 'None');
  }
}

// export default Unit;
