import moment from 'moment';

export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:MM:SS';

export const supportedDateFormats = [DATE_TIME_FORMAT, DATE_FORMAT, DATE_FORMAT_DEFAULT];

const getLocalDate = (date) => {
  let dt = new Date(date);
  const minutes = dt.getTimezoneOffset();
  dt = new Date(dt.getTime() + minutes * 60000);
  return dt;
};

export const formatDate = (date) =>
  // date = getLocalDate(date);
  moment(date, supportedDateFormats).format(DATE_FORMAT);

export const formatDateWithCurrentTime = (date) => {
  date = getLocalDate(date);
  const curDateTime = new Date();
  date.setHours(curDateTime.getHours());
  date.setMinutes(curDateTime.getMinutes());
  date.setMilliseconds(curDateTime.getMilliseconds());
  return moment(date, supportedDateFormats).format(DATE_TIME_FORMAT);
};

export const isValidDateRange = (start, date, end) => {
  const startDate = moment(start, supportedDateFormats);
  const itemDate = moment(date, supportedDateFormats);
  const endDate = moment(end, supportedDateFormats);
  return itemDate.isBetween(startDate, endDate, null, '[]');
};

export const isValidFromTo = (start, end) =>
  moment(start, supportedDateFormats).isSameOrBefore(moment(end, DATE_FORMAT));

export const isSame = (date1, date2) => {
  const d1 = moment(date1, supportedDateFormats);
  const d2 = moment(date2, supportedDateFormats);
  return d1.isSame(d2);
};

export const sortItemsByDate = (items) =>
  items.sort((a, b) =>
    moment(a.createdAt, supportedDateFormats).isSameOrBefore(
      moment(b.createdAt, supportedDateFormats)
    )
  );

export const sortItemsByDateWithField = (items, field) =>
  items.sort((a, b) =>
    moment(a[field], supportedDateFormats).isSameOrAfter(moment(b[field], supportedDateFormats))
  );

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let now = startDate.clone();
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now);
    now = now.add(1, 'days').clone();
  }
  return dates;
};
