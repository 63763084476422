import {
  ADD_NOTIFICATION,
  FETCH_NOTIFICATIONS,
  UPDATE_NOTIFICATION
} from '../actions/notificationActions';
import LocalNotification from '../../models/LocalNotification';

const initialState = {
  currentNotificationId: 1,
  notifications: []
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return state;
    case ADD_NOTIFICATION: {
      const { data } = action;
      // console.log(`In Add Notification: ${JSON.stringify(data)}`);
      const curNotificationId = state.currentNotificationId;
      const notification = new LocalNotification(
        curNotificationId.toString(),
        data.title,
        data.body,
        data.trigger,
        data.type
      );
      const updatedNotifications = [notification, ...state.notifications];

      return {
        ...state,
        notifications: updatedNotifications,
        currentNotificationId: curNotificationId + 1
      };
    }
    case UPDATE_NOTIFICATION: {
      const updatedData = action.data;
      const newNotification = new LocalNotification(
        updatedData.id,
        updatedData.title,
        updatedData.body,
        updatedData.trigger,
        updatedData.type
      );

      const finalNotifications = state.notifications.filter((noti) => noti.id !== updatedData.id);
      const updatedNotificationsArr = [newNotification, ...finalNotifications];

      return {
        ...state,
        notifications: updatedNotificationsArr
      };
    }
    default:
      return state;
  }
};

export default notificationReducer;
