export const FETCH_STOCK_ITEMS = 'FETCH_STOCK_ITEMS';
export const REFRESH_STOCK = 'REFRESH_STOCK';
export const ADD_STOCK_ITEM = 'ADD_STOCK_ITEM';
export const UPDATE_STOCK_ITEM = 'UPDATE_STOCK_ITEM';
export const INCREASE_STOCK = 'INCREASE_STOCK';
export const DECREASE_STOCK = 'DECREASE_STOCK';

export const fetchStock = () => ({ type: FETCH_STOCK_ITEMS });

export const refreshStock = () => (dispatch, getState) => {
  const { stock } = getState().stock;
  const stockItems = stock.items;

  const stockSettings = getState().settings.settings.stock;
  // console.log(`StockSettings - addItem:${JSON.stringify(stockSettings)}`);

  const { lowStockAlert } = stockSettings;
  const { alertQuantity } = stockSettings;
  const expiryAlert = stockSettings.itemExpiryAlert;
  const alertBefore = stockSettings.expiryAlertBefore;

  stockItems.forEach((itemData) => {
    if (lowStockAlert && parseFloat(itemData.quantity) <= parseFloat(alertQuantity)) {
      const curNotificationId = getState().notifications.currentNotificationId;
      // const notifications = scheduleLowStockAlertNotification(curNotificationId, itemData.title);
      // notifications.forEach((notification) =>
      //   dispatch({ type: ADD_NOTIFICATION, data: notification })
      // );
    }

    if (expiryAlert && itemData.isExpirable) {
      const curNotificationId = getState().notifications.currentNotificationId;
      // const notifications = scheduleExpiryNotification(
      //   curNotificationId,
      //   itemData.title,
      //   itemData.expiryDate,
      //   alertBefore
      // );
      // notifications.forEach((notification) =>
      //   dispatch({ type: ADD_NOTIFICATION, data: notification })
      // );
    }
  });

  dispatch({ type: REFRESH_STOCK });
};

export const addStockItem = (itemData) => (dispatch, getState) => {
  dispatch({ type: ADD_STOCK_ITEM, itemData });
};

export const updateStockItem = (itemData) => (dispatch, getState) => {
  dispatch({ type: UPDATE_STOCK_ITEM, itemData });
};

export const increaseStock = (items) => (dispatch, getState) => {
  const stockItems = getState().stock.stock.items;

  items.forEach((itemData) => {
    const updatedItem = stockItems.filter(
      (it) => it.title.toString() === itemData.title.toString()
    )[0];
    updatedItem.quantity = parseFloat(updatedItem.quantity) + parseFloat(itemData.quantity);
    updatedItem.price = itemData.price;
    updatedItem.expiryDate = itemData.expiryDate;
    updatedItem.isExpirable = itemData.isExpirable;

    // console.log(`Inc Stock Updated Item: ${JSON.stringify(updatedItem)}`);

    dispatch({ type: UPDATE_STOCK_ITEM, itemData: updatedItem });
  });
};

export const decreaseStock = (items) => (dispatch, getState) => {
  const stockItems = getState().stock.stock.items;

  items.forEach((itemData) => {
    // console.log(`ItemData: ${JSON.stringify(itemData)}`);
    const updatedItem = stockItems.filter(
      (it) => it.title.toString() === itemData.title.toString()
    )[0];
    updatedItem.quantity = parseFloat(updatedItem.quantity) - parseFloat(itemData.quantity);
    updatedItem.price = itemData.price;
    updatedItem.expiryDate = itemData.expiryDate;
    updatedItem.isExpirable = itemData.isExpirable;

    // console.log(`Inc Stock Updated Item: ${JSON.stringify(updatedItem)}`);

    dispatch({ type: UPDATE_STOCK_ITEM, itemData: updatedItem });
  });
};
