import {
  FETCH_RECEIPTS,
  ADD_RECEIPT,
  UPDATE_RECEIPT,
  REFRESH_RECEIPTS
} from '../actions/receiptsActions';
import Receipt from '../../models/Receipt';
import { formatDateWithCurrentTime } from '../../utils/DateUtils';

const initialState = {
  receipts: [],
  currentReceiptId: 1,
  prefix: 'RCPT-'
};

const sortItems = (items) => items.sort((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt));

const receiptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECEIPTS:
      return {
        ...state,
        receipts: sortItems(state.receipts)
      };
    case REFRESH_RECEIPTS:
      return state;
    case ADD_RECEIPT: {
      const { data } = action;
      const { currentReceiptId } = state;
      const receipt = new Receipt(
        currentReceiptId.toString(),
        data.receiptId,
        data.customerId,
        data.invoiceIds,
        data.amount,
        data.paymentType,
        formatDateWithCurrentTime(data.createdAt),
        formatDateWithCurrentTime(data.createdAt),
        data.bankAccount,
        data.notes
      );
      let updatedReceipts = [...state.receipts];
      updatedReceipts = updatedReceipts.concat(receipt);
      return {
        ...state,
        currentReceiptId: currentReceiptId + 1,
        receipts: sortItems(updatedReceipts)
      };
    }
    case UPDATE_RECEIPT: {
      const updatedReceiptData = action.data;
      const updatedReceipt = new Receipt(
        updatedReceiptData.id,
        updatedReceiptData.receiptId,
        updatedReceiptData.customerId,
        updatedReceiptData.invoiceIds,
        updatedReceiptData.amount,
        updatedReceiptData.paymentType,
        updatedReceiptData.createdAt,
        formatDateWithCurrentTime(new Date()),
        updatedReceiptData.bankAccount,
        updatedReceiptData.notes
      );
      let finalUpdatedReceipts = [...state.receipts];
      finalUpdatedReceipts = finalUpdatedReceipts.filter((item) => item.id !== updatedReceipt.id);
      finalUpdatedReceipts = finalUpdatedReceipts.concat(updatedReceipt);
      return {
        ...state,
        receipts: sortItems(finalUpdatedReceipts)
      };
    }
    default:
      return state;
  }
};

export default receiptsReducer;
