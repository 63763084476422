// material
import { Container, Stack, TextField, Typography } from '@material-ui/core';
// components
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as settingsActions from '../store/actions/settingsActions';
import Page from '../components/Page';
import TaxRate from '../models/TaxRate';
import Unit from '../models/Unit';
//
// ----------------------------------------------------------------------

export default function AddUnitTax(props) {
  const { id } = useParams();
  const { type, userType } = props;
  const [unitTax, setUnitTax] = useState({
    label: '',
    value: ''
  });
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();
  const { units, taxSlabs } = useSelector((state) => state.settings.settings);

  useEffect(() => {
    setTitle(renderTitle(type));
  }, [type]);

  const populateUserDetails = () => {
    let unitTax = {};
    if (userType === 'TAX') {
      unitTax = taxSlabs.find((taxSlab) => taxSlab.id.toString() === id.toString());
    } else {
      unitTax = units.find((unit) => unit.id.toString() === id.toString());
    }
    setUnitTax({
      label: unitTax.label,
      value: unitTax.value
    });
  };

  const renderTitle = (type) => {
    let title = '';
    const unitTax = userType === 'TAX' ? 'Tax' : 'Measurement Unit';
    switch (type) {
      case 'ADD':
        title = `Add ${unitTax}`;
        break;
      case 'EDIT':
        title = `Edit ${unitTax}`;
        populateUserDetails();
        break;
      default:
        title = `${unitTax} Details`;
        break;
    }
    return title;
  };
  const navigate = useNavigate();

  const UnitTaxSchema = Yup.object().shape({
    label: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Label required'),
    value: Yup.string().min(1, 'Too Short!').max(5, 'Too Long!').required('Value required')
  });

  const formik = useFormik({
    initialValues: unitTax,
    enableReinitialize: true,
    validationSchema: UnitTaxSchema,
    onSubmit: () => {
      const updatedUser = {
        label: getFieldProps('label').value,
        value: getFieldProps('value').value
      };
      if (type === 'ADD') {
        if (userType === 'TAX') {
          dispatch(
            settingsActions.addTax(
              new TaxRate(updatedUser.label, updatedUser.label, updatedUser.value)
            )
          );
        } else {
          dispatch(
            settingsActions.addUnit(
              new Unit(updatedUser.label, updatedUser.label, updatedUser.value)
            )
          );
        }
      } else if (type === 'EDIT') {
        if (userType === 'TAX') {
          dispatch(
            settingsActions.updateTax(
              new TaxRate(updatedUser.label, updatedUser.label, updatedUser.value)
            )
          );
        } else {
          dispatch(
            settingsActions.updateUnit(
              new Unit(updatedUser.label, updatedUser.label, updatedUser.value)
            )
          );
        }
      }
      if (type === 'EDIT') {
        navigate('./../../', { replace: true });
      } else {
        navigate('./../', { replace: true });
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Page title={`Dashboard: ${title} | Quick Bill`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Label"
                {...getFieldProps('label')}
                error={Boolean(touched.label && errors.label)}
                helperText={touched.label && errors.label}
              />

              <TextField
                fullWidth
                label="Value"
                {...getFieldProps('value')}
                error={Boolean(touched.value && errors.value)}
                helperText={touched.value && errors.value}
              />

              {(type === 'ADD' || type === 'EDIT') && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Details
                </LoadingButton>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
