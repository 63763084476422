// material
import { Button, Container, Stack, TextField, Typography } from '@material-ui/core';
// components
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import Page from '../../../components/Page';
import * as settingsActions from '../../../store/actions/settingsActions';
//
// ----------------------------------------------------------------------

export default function AddBankAccount(props) {
  const { id } = useParams();
  const { type } = props;
  const [bankAccount, setBankAccount] = useState({
    accountName: '',
    accountNumber: '',
    bankName: '',
    branchName: '',
    ifscCode: ''
  });
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();
  const { bankAccounts } = useSelector((state) => state.settings.settings);

  useEffect(() => {
    setTitle(renderTitle(type));
  }, [type]);

  const renderTitle = (type) => {
    const populateBankAccountDetails = () => {
      const bankAccount = bankAccounts.find((acc) => acc.id.toString() === id.toString());
      if (bankAccount) {
        setBankAccount({ ...bankAccount });
      }
    };
    let title = '';
    switch (type) {
      case 'ADD':
        title = 'Add Bank Account';
        break;
      case 'EDIT':
        title = 'Edit Bank Account';
        break;
      default:
        title = 'Bank Account Details';
        populateBankAccountDetails();
        break;
    }
    return title;
  };
  const navigate = useNavigate();

  const BankAccountSchema = Yup.object().shape({
    accountName: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Account Name required'),
    accountNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Account Number required'),
    bankName: Yup.string().required('Bank Name is required'),
    branchName: Yup.string().required('Branch Name is required'),
    ifscCode: Yup.string().required('IFSC code is required')
  });

  const formik = useFormik({
    initialValues: bankAccount,
    enableReinitialize: true,
    validationSchema: BankAccountSchema,
    onSubmit: () => {
      const updatedBankAccount = {
        accountName: getFieldProps('accountName').value,
        accountNumber: getFieldProps('accountNumber').value,
        bankName: getFieldProps('bankName').value,
        branchName: getFieldProps('branchName').value,
        ifscCode: getFieldProps('ifscCode').value
      };
      console.log(`TYPE: ${type}`);
      if (type === 'ADD') {
        dispatch(settingsActions.addBankAccount(updatedBankAccount));
      } else if (type === 'EDIT') {
        dispatch(settingsActions.updateBankAccount(updatedBankAccount));
      }
      navigate('/dashboard/settings/bankAccount', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Page title={`Dashboard: ${title} | Quick Bill`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`./../../edit/${id}`}
            startIcon={<Icon icon={editFill} />}
          >
            Edit Bank Account
          </Button>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Account Name"
                {...getFieldProps('accountName')}
                error={Boolean(touched.accountName && errors.accountName)}
                helperText={touched.accountName && errors.accountName}
                disabled={type === 'VIEW'}
              />

              <TextField
                fullWidth
                label="Account Number"
                {...getFieldProps('accountNumber')}
                error={Boolean(touched.accountNumber && errors.accountNumber)}
                helperText={touched.accountNumber && errors.accountNumber}
                disabled={type === 'VIEW'}
                type="number"
              />

              <TextField
                fullWidth
                label="Bank Name"
                {...getFieldProps('bankName')}
                error={Boolean(touched.bankName && errors.bankName)}
                helperText={touched.bankName && errors.bankName}
                disabled={type === 'VIEW'}
              />

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Branch name"
                  {...getFieldProps('branchName')}
                  error={Boolean(touched.branchName && errors.branchName)}
                  helperText={touched.branchName && errors.branchName}
                  disabled={type === 'VIEW'}
                />

                <TextField
                  fullWidth
                  label="IFSC Code"
                  {...getFieldProps('ifscCode')}
                  error={Boolean(touched.ifscCode && errors.ifscCode)}
                  helperText={touched.ifscCode && errors.ifscCode}
                  disabled={type === 'VIEW'}
                />
              </Stack>

              {(type === 'ADD' || type === 'EDIT') && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Details
                </LoadingButton>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
