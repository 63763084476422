import { toTwoDecimalPlaces } from '../utils/FloatUtils';
import { DEFUALT_BANK_ACCOUNT_NONE, PAYMENT_STATUSES } from '../config/data/init';
import { convertNumberToWordsWithDecimal } from '../utils/formatNumber';

const renderItemRow = (item, index) => {
  // console.log({ ...item });
  const quantity = toTwoDecimalPlaces(item.quantity);
  const mrp = toTwoDecimalPlaces(item.mrp);
  const itemDiscountTotal = toTwoDecimalPlaces(
    parseFloat(item.quantity) * parseFloat(item.itemDiscount)
  );
  const total = toTwoDecimalPlaces(
    parseFloat(item.quantity) * (parseFloat(item.mrp) - parseFloat(item.itemDiscount))
  );
  const taxRate = parseFloat(item.tax.value);
  const cgst = toTwoDecimalPlaces((taxRate * total) / 100);
  return `<tr>
            <td>${index}</td>
            <td style="word-wrap: break-word; max-width: 200px;">
                ${item.title.toString().toUpperCase()}</td>
            <td>${item.hsncode}</td>
            <td>${item.unit.label}</td>
            <td>${quantity}</td>
            <td>${mrp}</td>
            <td>${itemDiscountTotal}</td>
            <td>${cgst}</td>
            <td>${taxRate}%</td>
            <td>${cgst}</td>
            <td>${taxRate}%</td>
            <td>${total}</td>
          </tr>
          <tr>
            <td></td>
            <td colspan="12" style="word-wrap: break-word; max-width: 100%;">
                ${item.description.toString()}
            </td>
          </tr>`;
};

const renderPersonThroughRow = (personThrough) =>
  `<tr><td>Person through</td><td>${personThrough}</td></tr>`;

export const invoicePreviewHtmlTemplate = (
  type,
  receiptType,
  companyDetails,
  customerDetails,
  invoiceDetails,
  billingSettings,
  bankSettings
) => {
  let receiptTitle;
  switch (receiptType) {
    case 1:
      receiptTitle = 'TAX INVOICE';
      break;
    case 2:
      receiptTitle = 'DUPLICATE COPY';
      break;
    case 3:
      receiptTitle = 'TRIPLICATE COPY';
      break;
    case 4:
      receiptTitle = 'EXTRA COPY';
      break;
    default:
      receiptTitle = 'TAX INVOICE';
  }

  const currencySymbol = billingSettings.currencySymbol.label;
  const isInvoice = type === 'INVOICE';
  const invoiceIdTitle = isInvoice ? 'Inv Id' : 'Order Id';
  const invoiceId = isInvoice ? invoiceDetails.invoiceId : invoiceDetails.orderId;
  const suppInvId = isInvoice ? '' : invoiceDetails.supplierInvoiceId;
  const supplierInvoiceIdRow = isInvoice
    ? ''
    : `<tr><td>Supplier Inv Id</td><td>${suppInvId}</td></tr>`;
  const personThroughRow = invoiceDetails.personThrough
    ? renderPersonThroughRow(invoiceDetails.personThrough)
    : '';
  const totalItems = invoiceDetails.items.length;
  const items = invoiceDetails.items
    .map((item, index) => renderItemRow(item, index + 1))
    .reduce((a, b) => `${a}\n${b}`);

  const gstSummary = {};
  let totalTaxable = 0.0;
  invoiceDetails.items.forEach((item) => {
    const taxSlab = item.tax;
    const amount =
      parseFloat(item.quantity) * (parseFloat(item.mrp) - parseFloat(item.itemDiscount));
    const taxRate = parseFloat(taxSlab.value) * 2;
    const taxableAmount = toTwoDecimalPlaces(amount - (amount * taxRate) / 100);
    // console.log(taxRate, taxableAmount);
    totalTaxable += taxableAmount;
    const taxAmount = (taxableAmount * taxRate) / 100;
    const cgst = toTwoDecimalPlaces(taxAmount / 2);
    if (!gstSummary[taxSlab.label]) {
      gstSummary[taxSlab.label] = { cgst: 0.0, sgst: 0.0, total: 0.0 };
    }
    gstSummary[taxSlab.label].cgst = parseFloat(gstSummary[taxSlab.label].cgst) + parseFloat(cgst);
    gstSummary[taxSlab.label].sgst = parseFloat(gstSummary[taxSlab.label].sgst) + parseFloat(cgst);
    gstSummary[taxSlab.label].total =
      parseFloat(gstSummary[taxSlab.label].total) + parseFloat(taxableAmount);
  });

  const taxTotal = toTwoDecimalPlaces(
    parseFloat(invoiceDetails.total) -
      parseFloat(totalTaxable) +
      parseFloat(invoiceDetails.overallDiscountTotal) -
      parseFloat(invoiceDetails.shippingCharges)
  );
  const cgstTotal = toTwoDecimalPlaces(taxTotal / 2);

  const termsAndConditions = billingSettings.termsAndConditions
    .toString()
    .split('\n')
    .map((term) => `<p>${term}</p>`)
    .reduce((a, b) => `${a}\n${b}`);

  const paymentStatus = invoiceDetails.paymentStatus.label.toString().toUpperCase();
  const isPaid = paymentStatus === PAYMENT_STATUSES.PAID.label.toString().toUpperCase();

  const amountInWords = (amount) =>
    `<p class="bold">Rupees ${convertNumberToWordsWithDecimal(amount)} only</p>`;

  const bankDetails = bankSettings[0] ?? DEFUALT_BANK_ACCOUNT_NONE;
  const base64Prefix = `data:image/jpeg;base64,`;
  // eslint-disable-next-line no-nested-ternary
  const companyLogo = companyDetails.photoURL
    ? companyDetails.photoURL.preview
      ? base64Prefix + companyDetails.photoURL.preview
      : null
    : null;
  return `
    <!DOCTYPE html>
    <html lang="en" dir="ltr">
        <head>
            <meta charset="utf-8">
            <title>Invoice Preview</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
                  integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous"/>
            <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
                    integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
                    crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"
                    integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN"
                    crossorigin="anonymous"></script>
            <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"
                    integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV"
                    crossorigin="anonymous"></script>
                    
            <style>
                @media print { * { overflow: hidden!important; } }
                body {
                    margin: auto;
                }
        
                hr {
                    margin: 5px;
                }
                
                p {
                    margin-bottom: 5px;
                }
                
                .bold {
                    font-weight: bold;
                }
                
                .logo {
                    width: 75px;
                    height: 75px;
                    padding-left: 500px;
                    border-left: 400px;
                }
                
                .table, th, td {
                    /*border: 1px solid black;*/
                    border-collapse: collapse;
                }
                
                .heading {
                    font-size: 16px;
                    font-weight: bold;
                }
                
                .first {
                    margin: 40px 2% 2%;
                }
                
                .second {
                    margin: 0 2%;
                }
                
                .third {
                    margin: 0 2%;
                }
                
                .sub {
                    font-size: 20px;
                }
                
                .fourth {
                    margin: 0 2%;
                }
                
                .last {
                    margin: 0 2%;
                    border-bottom: 0;
                }
                
                table {
                    empty-cells: show;
                }
            </style>
        </head>
        <body>
            <!--first-->
            <div class="first">
                <div class="row">
                    <div class="col-12 text-center">
                        <h5><strong>${receiptTitle}</strong></h5>
                    </div>
                </div>
            </div>
            <hr>
            
            <!--first-->
            <div class="first">
                <div class="row">
                    <!-- Company Logo -->
                    <div class="col-2">
                        <img height="200px"
                             width="200px"
                             src="${companyLogo}"
                             alt="${companyDetails.firmName}">
                    </div>
                    
                    <!-- Company Details -->
                    <div class="col-5" style="margin-top: 3%">
                        <h6 class="heading">${companyDetails.firmName}</h6>
                        <p>${companyDetails.firmAddress}</p>
                        <p>${companyDetails.phoneNumber}</p>
                        <p>${companyDetails.gstNumber}</p>
                        <hr>
                        <h5 class="bold green">Payment Status: <span style="color: ${
                          isPaid ? 'green' : 'red'
                        }">${paymentStatus}</span></h5>
                    </div>
                    
                    <!-- Invoice Details -->
                    <div class="col-5">
                        <div class="table table-striped">
                            <table style="width:100%">
                                <tr>
                                    <td>${invoiceIdTitle}#</td>
                                    <td>${invoiceId}</td>
                                </tr>
                                <tr>
                                    <td>Created At</td>
                                    <td>${invoiceDetails.createdAt}</td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td>${currencySymbol} ${invoiceDetails.total}</td>
                                </tr>
                                <tr>
                                    <td>Balance Amount</td>
                                    <td>${currencySymbol} ${invoiceDetails.balance}</td>
                                </tr>
                                ${supplierInvoiceIdRow}
                                ${personThroughRow}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
    
            <!---second-->
            <div class="second">
                <div class="row">
                    <div class="col-4">
                        <h1 class="heading">Customer:</h1>
                        <h2 class="sub">${customerDetails.name}</h2>
                        <p>${customerDetails.shippingAddress}</p>
                        <p>Phone: ${
                          customerDetails.id !== '-1'
                            ? customerDetails.phoneNumber
                            : 'Not Available'
                        }</p>
                        <p>GSTIN: ${
                          customerDetails.gstNumber ? customerDetails.gstNumber : 'Not Available'
                        }</p>
                    </div>
                    <div class="col-4">
                        <h1 class="heading">Billing Address:</h1>
                        <h2 class="sub">${customerDetails.name}</h2>
                        <p>${customerDetails.shippingAddress}</p>
                        <p>Phone: ${
                          customerDetails.id !== '-1'
                            ? customerDetails.phoneNumber
                            : 'Not Available'
                        }</p>
                        <p>GSTIN: ${
                          customerDetails.gstNumber ? customerDetails.gstNumber : 'Not Available'
                        }</p>
                    </div>
                    <div class="col-4">
                        <h1 class="heading">Shipping Address:</h1>
                        <h2 class="sub">${customerDetails.name}</h2>
                        <p>${customerDetails.shippingAddress}</p>
                        <p>Phone: ${
                          customerDetails.id !== '-1'
                            ? customerDetails.phoneNumber
                            : 'Not Available'
                        }</p>
                        <p>GSTIN: ${
                          customerDetails.gstNumber ? customerDetails.gstNumber : 'Not Available'
                        }</p>
                    </div>
                </div>
            </div>
            <hr>
    
            <!--third-->
            <div class="third">
                <div class="row">
                    <table style="width:100%" class="table">
                        <tr>
                            <th>#</th>
                            <th>Product Description</th>
                            <th>HSN</th>
                            <th>UOM</th>
                            <th>Qty</th>
                            <th>Price(${currencySymbol})</th>
                            <th>Item Discount(${currencySymbol})</th>
                            <th>CGST(${currencySymbol})</th>
                            <th>Rate(%)</th>
                            <th>SGST(${currencySymbol})</th>
                            <th>Rate(%)</th>
                            <th>Amount(${currencySymbol})</th>
                        </tr>
                        ${items}
                        <tr>
                            <td></td>
                            <td class="bold">Total items</td>
                            <td class="bold">${totalItems}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="bold">Grand Total</td>
                            <td class="bold">${cgstTotal}</td>
                            <td class="bold"></td>
                            <td class="bold">${cgstTotal}</td>
                            <td class="bold"></td>
                            <td class="bold">${toTwoDecimalPlaces(invoiceDetails.total)}</td>
                        </tr>
                    </table>
                </div>
            </div>
    
            <!--four-->
            <div class="fourth">
                <div class="row">
                    <table style="width:100%" class="table table-bordered">
                    <tr>
                        <th class="head col-3">Bank Details:</th>
                        <td class="head col-3"></td>
                        <th class="head col-3">Summary</th>
                        <td class="head col-3"></td>
                    </tr>
                    <tr>
                        <td class="head col-3">Account Name</td>
                        <td class="head col-3">${bankDetails.accountName}</td>
                        <td class="head col-3">Sub total (${currencySymbol})</td>
                        <td class="head col-3">${toTwoDecimalPlaces(totalTaxable)}</td>
                    </tr>
                    <tr>
                        <td class="head col-3">Bank Name</td>
                        <td class="head col-3">${bankDetails.bankName}</td>
                        <td class="head col-3">GST Total (${currencySymbol})</td>
                        <td class="head col-3">${toTwoDecimalPlaces(taxTotal)}</td>
                    </tr>
                    <tr>
                        <td class="head col-3">A/c Number</td>
                        <td class="head col-3">${bankDetails.accountNumber}</td>
                        <td class="head col-3">Discount (${currencySymbol})</td>
                        <td class="head col-3">${toTwoDecimalPlaces(
                          invoiceDetails.overallDiscountTotal
                        )}</td>
                    </tr>
                    <tr>
                        <td class="head col-3">Branch</td>
                        <td class="head col-3">${bankDetails.branchName}</td>
                        <td class="head col-3">Shipping (${currencySymbol})</td>
                        <td class="head col-3">${toTwoDecimalPlaces(
                          invoiceDetails.shippingCharges
                        )}</td>
                    </tr>
                    <tr>
                        <td class="head col-3">IFSC Code</td>
                        <td class="head col-3">${bankDetails.ifscCode}</td>
                        <td class="head col-3 bold">Grand Total (${currencySymbol})</td>
                        <td class="head col-3 bold">${toTwoDecimalPlaces(invoiceDetails.total)}</td>
                    </tr>
                </table>
                </div>
            </div>
    
            <!--five-->
            <div class="last">
                <div class="row">
                    <table class="table table-bordered" style="width:100%">
                    <tr>
                        <th style="width:50%">Terms & Conditions:</th>
                        <th style="width:50%">Amount in Words</th>
                    </tr>
                    <tr>
                        <td style="width:50%">${termsAndConditions}</td>
                        <td style="width:50%">${amountInWords(invoiceDetails.total)}</td>
                    </tr>
                </table>
                </div>
            </div>
            
            <!--five-->
            <div class="last">
                <div class="row">
                    <table style="width:100%">
                      <tr>
                          <th colspan="6"></th>
                          <th colspan="6" style="text-align: right;">For ${
                            companyDetails.firmName
                          }</th>
                      </tr>
                      <tr>
                          <td colspan="6"><br><br></td>
                          <td colspan="6"><br><br></td>
                      </tr>
                      <tr>
                          <td colspan="6">Customer Signature</td>
                          <td colspan="6" style="text-align: right;">Authorised Signatory</td>
                      </tr>
                    </table>
                </div>
            </div>
            
            <!--last-->
            <div class="first">
                <div class="row">
                    <div class="col-12 text-center">
                        <h5><strong>${billingSettings.thankYouNotes}</strong></h5>
                    </div>
                </div>
            </div>
            <hr>
    
            <br>
            <br>
            <br>
        </body>
    </html>`;
};
