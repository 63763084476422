import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { useDispatch } from 'react-redux';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/authentication/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import BankAccounts from './pages/BankAccounts';
import Blog from './pages/Blog';
import NotFound from './pages/Page404';
import Customer from './pages/Customer';
import Supplier from './pages/Supplier';
import TaxRate from './pages/TaxRate';
import MeasurementUnit from './pages/MeasurementUnit';
import Payment from './pages/Payment';
import Receipt from './pages/Receipt';
import Invoice from './pages/Invoice';
import PurchaseOrder from './pages/PurchaseOrder';
import Stock from './pages/Stock';
import AddBankAccount from './layouts/dashboard/bankAccount/AddBankAccount';
import AddUser from './pages/AddUser';
import AddUnitTax from './pages/AddUnitTax';
import AddStock from './pages/AddStock';
import AddInvoicePO from './pages/AddInvoicePO';
import AddInvoiceItem from './pages/AddInvoiceItem';
import Profile from './pages/Profile';
import AddReceiptPayment from './pages/AddReceiptPayment';
import AuthGuard from './guards/AuthGuard';
import PrintPreview from './pages/PrintPreview';
import ReceiptPaymentPrintPreview from './pages/ReceiptPaymentPrintPreview';
import CustomerSupplierReport from './pages/CustomerSupplierReport';
import ImportData from './pages/ImportData';
import LogOut from './pages/LogOut';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user/profile', element: <Profile /> },
        { path: 'invoice', element: <Invoice /> },
        { path: 'invoice/add', element: <AddInvoicePO type="ADD" userType="INVOICE" /> },
        {
          path: 'invoice/add/item',
          element: <AddInvoiceItem type="ADD" userType="INVOICE_ITEM" />
        },
        { path: 'invoice/edit/:id', element: <AddInvoicePO type="EDIT" userType="INVOICE" /> },
        { path: 'invoice/print/:id', element: <PrintPreview type="INVOICE" /> },
        { path: 'purchaseOrder', element: <PurchaseOrder /> },
        {
          path: 'purchaseOrder/add',
          element: <AddInvoicePO type="ADD" userType="PURCHASE_ORDER" />
        },
        {
          path: 'purchaseOrder/edit/:id',
          element: <AddInvoicePO type="EDIT" userType="PURCHASE_ORDER" />
        },
        { path: 'purchaseOrder/print/:id', element: <PrintPreview type="PURCHASE_ORDER" /> },
        { path: 'receipt', element: <Receipt /> },
        { path: 'receipt/add', element: <AddReceiptPayment type="ADD" userType="RECEIPT" /> },
        { path: 'receipt/edit/:id', element: <AddReceiptPayment type="EDIT" userType="RECEIPT" /> },
        { path: 'receipt/print/:id', element: <ReceiptPaymentPrintPreview type="RECEIPT" /> },
        { path: 'payment', element: <Payment /> },
        { path: 'payment/add', element: <AddReceiptPayment type="ADD" userType="PAYMENT" /> },
        { path: 'payment/edit/:id', element: <AddReceiptPayment type="EDIT" userType="PAYMENT" /> },
        { path: 'payment/print/:id', element: <ReceiptPaymentPrintPreview type="PAYMENT" /> },
        { path: 'reports/customer', element: <CustomerSupplierReport type="CUSTOMER" /> },
        { path: 'reports/supplier', element: <CustomerSupplierReport type="SUPPLIER" /> },
        { path: 'reports/sales', element: <CustomerSupplierReport type="SALES" /> },
        { path: 'reports/purchase', element: <CustomerSupplierReport type="PURCHASE" /> },
        { path: 'reports/stock', element: <CustomerSupplierReport type="STOCK" /> },
        { path: 'customer/add', element: <AddUser type="ADD" userType="CUSTOMER" /> },
        { path: 'customer/edit/:id', element: <AddUser type="EDIT" userType="CUSTOMER" /> },
        { path: 'customer', element: <Customer /> },
        { path: 'supplier/add', element: <AddUser type="ADD" userType="SUPPLIER" /> },
        { path: 'supplier/edit/:id', element: <AddUser type="EDIT" userType="SUPPLIER" /> },
        { path: 'supplier', element: <Supplier /> },
        { path: 'stock', element: <Stock /> },
        { path: 'stock/add', element: <AddStock type="ADD" userType="STOCK" /> },
        { path: 'stock/edit/:id', element: <AddStock type="EDIT" userType="STOCK" /> },
        { path: 'settings/bankAccount', element: <BankAccounts /> },
        { path: 'settings/bankAccount/view/:id', element: <AddBankAccount type="VIEW" /> },
        { path: 'settings/bankAccount/add', element: <AddBankAccount type="ADD" /> },
        { path: 'settings/bankAccount/edit/:id', element: <AddBankAccount type="EDIT" /> },
        { path: 'settings/taxRate', element: <TaxRate /> },
        { path: 'settings/taxRate/edit/:id', element: <AddUnitTax type="EDIT" userType="TAX" /> },
        { path: 'settings/taxRate/add', element: <AddUnitTax type="ADD" userType="TAX" /> },
        { path: 'settings/unit', element: <MeasurementUnit /> },
        {
          path: 'settings/unit/add',
          element: <AddUnitTax type="ADD" userType="MEASUREMENTUNIT" />
        },
        {
          path: 'settings/unit/edit/:id',
          element: <AddUnitTax type="EDIT" userType="MEASUREMENTUNIT" />
        },
        { path: 'settings/import/stock', element: <ImportData type="STOCK" /> },
        { path: 'blog', element: <Blog /> },
        { path: 'logout', element: <LogOut /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'logout', element: <LogOut /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
