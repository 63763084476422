import {
  ADD_STOCK_ITEM,
  FETCH_STOCK_ITEMS,
  UPDATE_STOCK_ITEM,
  INCREASE_STOCK,
  DECREASE_STOCK,
  REFRESH_STOCK
} from '../actions/stockActions';
import Stock from '../../models/Stock';

const initialState = {
  stock: {
    items: [],
    currentStockItemId: 1
  }
};

const sortItems = (items) =>
  items.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_STOCK:
      return state;
    case FETCH_STOCK_ITEMS:
      // console.log(`Fetch Stock:${JSON.stringify(state.stock.items)}`);
      return {
        ...state,
        stock: {
          ...state.stock,
          items: sortItems(state.stock.items)
        }
      };
    case ADD_STOCK_ITEM: {
      const { currentStockItemId } = state.stock;
      const { itemData } = action;
      const item = new Stock(
        currentStockItemId.toLocaleString(),
        itemData.title,
        itemData.description,
        itemData.quantity,
        itemData.unit,
        itemData.mrp,
        itemData.tax,
        itemData.hsncode,
        itemData.expiryDate,
        itemData.isExpirable
      );
      let updatedItems = [...state.stock.items];
      updatedItems = updatedItems.concat(item);
      return {
        ...state,
        stock: {
          ...state.stock,
          items: sortItems(updatedItems),
          currentStockItemId: currentStockItemId + 1
        }
      };
    }
    case UPDATE_STOCK_ITEM: {
      const data = action.itemData;
      const updateItem = new Stock(
        data.id,
        data.title,
        data.description,
        data.quantity,
        data.unit,
        data.mrp,
        data.tax,
        data.hsncode,
        data.expiryDate,
        data.isExpirable
      );
      let finalUpdatedItems = [...state.stock.items];
      finalUpdatedItems = finalUpdatedItems.filter((item) => item.id !== updateItem.id);
      finalUpdatedItems = finalUpdatedItems.concat(updateItem);
      return {
        ...state,
        stock: {
          ...state.stock,
          items: sortItems(finalUpdatedItems)
        }
      };
    }
    case INCREASE_STOCK: {
      const { items } = action;
      let finalUpdatedStockItems = [...state.stock.items];
      items.forEach((item) => {
        const updatedItem = finalUpdatedStockItems.filter((it) => it.id === item.id)[0];
        updatedItem.quantity = parseFloat(updatedItem.quantity) + parseFloat(item.quantity);
        updatedItem.price = item.price;

        finalUpdatedStockItems = finalUpdatedStockItems.filter((it) => it.id !== item.id);
        finalUpdatedStockItems = finalUpdatedStockItems.concat(updatedItem);
      });
      return {
        ...state,
        stock: {
          ...state.stock,
          items: sortItems(finalUpdatedStockItems)
        }
      };
    }
    case DECREASE_STOCK: {
      const itemSet = action.items;
      let finalUpdatedStockList = [...state.stock.items];
      itemSet.forEach((item) => {
        const updatedItem = finalUpdatedStockList.filter((it) => it.id === item.id)[0];
        updatedItem.quantity = parseFloat(updatedItem.quantity) - parseFloat(item.quantity);
        updatedItem.price = item.price;

        finalUpdatedStockList = finalUpdatedStockList.filter((it) => it.id !== item.id);
        finalUpdatedStockList = finalUpdatedStockList.concat(updatedItem);
      });
      return {
        ...state,
        stock: {
          ...state.stock,
          items: sortItems(finalUpdatedStockList)
        }
      };
    }
    default:
      return state;
  }
};

export default stockReducer;
