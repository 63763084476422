import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const TaxRate = () => {
  const TABLE_HEAD = [
    { id: 'id', label: 'Tax Rate Id', alignRight: false },
    { id: 'label', label: 'Tax Rate Label', alignRight: false },
    { id: 'value', label: 'Tax Rate Value', alignRight: false },
    { id: '' }
  ];

  const { taxSlabs } = useSelector((state) => state.settings.settings);

  return <User title="Tax Rate" tableHeader={TABLE_HEAD} data={taxSlabs} type="TAX" />;
};

export default TaxRate;
