import { toTwoDecimalPlaces } from '../utils/FloatUtils';
import { formatDate, sortItemsByDateWithField } from '../utils/DateUtils';

const renderItemRow = (item) => `
        <tr>
            <th colspan="2">${formatDate(item.date)}</th>
            <th colspan="4">${item.description}</th>
            <th colspan="2">${item.debit}</th>
            <th colspan="2">${item.credit}</th>
            <th colspan="2">${item.balance}</th>
      </tr>
    `;

export const UserReportTemplate = (
  type,
  user,
  fromDate,
  toDate,
  invoices,
  receipts,
  currencySymbol,
  firmDetails
) => {
  const isCustomer = type === 'CUSTOMER';
  const title = isCustomer ? 'Customer Report' : 'Supplier Report';
  const userTitle = isCustomer ? 'Customer Details' : 'Supplier Details';
  const zeroFloat = toTwoDecimalPlaces('0');
  const summary = { paid: 0, due: 0 };

  const receiptRows = receipts.map((item) => {
    const invoiceItem = {};
    const invoiceId = isCustomer ? item.receiptId : item.paymentId;
    const notes = item.notes ? `- ${item.notes}` : '';
    const description = `Receipt - ${invoiceId} ${notes}`;
    invoiceItem.date = item.createdAt;
    invoiceItem.description = description;
    if (isCustomer) {
      invoiceItem.debit = item.amount;
      invoiceItem.credit = zeroFloat;
    } else {
      invoiceItem.credit = item.amount;
      invoiceItem.debit = zeroFloat;
    }

    invoiceItem.balance = zeroFloat;
    const paid = parseFloat(invoiceItem.debit);
    summary.paid += paid;
    summary.due += parseFloat(invoiceItem.credit) - paid;
    return invoiceItem;
  });
  const invoiceRows = invoices.map((item) => {
    const invoiceItem = {};
    const invoiceId = isCustomer ? item.invoiceId : item.orderId;
    const notes = item.notes ? `- ${item.notes}` : '';
    const description = `Invoice - ${invoiceId} ${notes}`;
    invoiceItem.date = item.createdAt;
    invoiceItem.description = description;
    if (isCustomer) {
      invoiceItem.debit = zeroFloat;
      invoiceItem.credit = item.total;
    } else {
      invoiceItem.credit = zeroFloat;
      invoiceItem.debit = item.total;
    }
    const paid = parseFloat(invoiceItem.debit);
    summary.paid += paid;
    summary.due += parseFloat(invoiceItem.credit) - paid;
    return invoiceItem;
  });

  const rows = sortItemsByDateWithField([...invoiceRows, ...receiptRows], 'date');

  let tableRows;
  if (rows.length > 0) {
    let prevBal = 0.0;
    tableRows = rows
      .map((row) => {
        const curBalance = parseFloat(prevBal) + parseFloat(row.credit) - parseFloat(row.debit);
        row.balance = toTwoDecimalPlaces(curBalance);
        prevBal = curBalance;
        return renderItemRow(row);
      })
      .reduce((a, b) => `${a}\n${b}`, '');
  } else {
    tableRows = '<tr><td></td><td colspan="12" style="text-align: center;">No Data found</td></tr>';
  }

  return `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8"/>
    <meta name="viewport" content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0, user-scalable=1"/>
    <title>${title}</title>
    <style>
      html {
        margin: auto;
        text-align: left;
      }

      .alnright { text-align: right; }
      .alnleft { text-align: left; }
      .alncenter { text-align: center; }

      p {
        margin: 5px;
      }

      table {
        width: 100%; padding: 10px;
      }
    </style>
</head>
<body>
    <div class="alncenter" id="title">
      <h2><u>Balance Statement</u></h2>
    </div>
    <div id="header">
      <table>
        <tr colspan="12">
          <td colspan="6">
            <div>
              <p><strong><u>Firm Details:</u></strong></p>
              <p>${firmDetails.firmName}</p>
              <p>+91 ${firmDetails.phoneNumber}</p>
              <p>${firmDetails.firmAddress}</p>
              <p>${firmDetails.gstNumber}</p>
            </div>
          </td>
          <td colspan="6" class="alnright">
            <div class="alnleft" style="padding-left: 50%">
              <p><strong><u>${userTitle}:</u></strong></p>
              <p>${user.name}</p>
              <p>${user.id !== '-1' ? `+91 ${user.phoneNumber}` : ''}</p>
              <p>${user.shippingAddress}</p>
              <p>${user.gstNumber ? user.gstNumber : ''}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <hr />
    <div id="summary">
      <p><strong>Summary:</strong></p>
      <table>
        <tr>
          <td>Period: ${fromDate} - ${toDate}</td>
        </tr>
        <tr>
          <td>Total Paid: <b>${currencySymbol.label} ${toTwoDecimalPlaces(summary.paid)}</b></td>
        </tr>
        <tr>
          <td>Total Due: <b>${currencySymbol.label} ${toTwoDecimalPlaces(summary.due)}</b></td>
        </tr>
      </table>
    </div>
    <hr />
    <div id="summary-table">
      <table>
        <thead>
          <tr colspan="12">
            <th colspan="2">Date</th>
            <th colspan="4">Description</th>
            <th colspan="2">Debit (${currencySymbol.label})</th>
            <th colspan="2">Credit (${currencySymbol.label})</th>
            <th colspan="2">Balance (${currencySymbol.label})</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    </div>
  </body>
</html>
    `;
};
