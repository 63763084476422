class LocalNotification {
  constructor(id, title, body, trigger, type) {
    this.id = id;
    this.title = title;
    this.body = body;
    this.trigger = trigger;
    this.type = type;
  }
}

export default LocalNotification;
