import { Icon } from '@iconify/react';
import { capitalCase } from 'change-case';
import { useState, useEffect } from 'react';
import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundAccountBalance from '@iconify/icons-ic/round-account-balance';
// material
import { Container, Tab, Box, Tabs, Stack } from '@material-ui/core';
import Page from '../components/Page';
import PersonalProfile from './PersonalProfile';
// import ChangePassword from './ChangePassword';
import CompanyProfile from './CompanyProfile';

// ----------------------------------------------------------------------

export default function Profile() {
  const [currentTab, setCurrentTab] = useState('general');

  useEffect(() => {}, []);

  const ACCOUNT_TABS = [
    {
      value: 'general',
      icon: <Icon icon={roundAccountBox} width={20} height={20} />,
      component: <PersonalProfile />
    },
    {
      value: 'company_profile',
      icon: <Icon icon={roundAccountBalance} width={20} height={20} />,
      component: <CompanyProfile />
    }
    // {
    //   value: 'change_password',
    //   icon: <Icon icon={roundVpnKey} width={20} height={20} />,
    //   component: <ChangePassword />
    // }
  ];

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Page title="User: Profile | QuickBill">
      <Container>
        <Stack spacing={5}>
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleChangeTab}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          {ACCOUNT_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Stack>
      </Container>
    </Page>
  );
}
