import {
  ADD_CUSTOMER,
  FETCH_CUSTOMERS,
  REFRESH_CUSTOMERS,
  UPDATE_CUSTOMER
} from '../actions/customersActions';
import { CUSTOMERS } from '../../config/data/init';

const initialState = {
  customers: [...CUSTOMERS]
};

const sortItems = (items) =>
  items.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: sortItems(state.customers)
      };
    case REFRESH_CUSTOMERS:
      return state;
    case ADD_CUSTOMER: {
      const customer = action.data;
      let updatedCustomers = [...state.customers];
      updatedCustomers = updatedCustomers.concat(customer);
      return {
        ...state,
        customers: sortItems(updatedCustomers)
      };
    }
    case UPDATE_CUSTOMER: {
      const updatedCustomer = action.data;
      let finalUpdatedCustomers = [...state.customers];
      finalUpdatedCustomers = finalUpdatedCustomers.filter(
        (item) => item.id !== updatedCustomer.id
      );
      finalUpdatedCustomers = finalUpdatedCustomers.concat(updatedCustomer);
      return {
        ...state,
        customers: sortItems(finalUpdatedCustomers)
      };
    }
    default:
      return state;
  }
};

export default customersReducer;
