// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppCustomers,
  AppSuppliers,
  AppPurchaseOrders,
  AppNewsUpdate,
  AppInvoices,
  AppOrderTimeline,
  AppFinancialOverview,
  AppSalesStats,
  AppTrafficBySite,
  AppCurrentSubject,
  AppLowStockItems
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Dashboard | QuickBill">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppInvoices />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppCustomers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppPurchaseOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppSuppliers />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppSalesStats />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppFinancialOverview />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppLowStockItems />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          {/*
          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
          */}
        </Grid>
      </Container>
    </Page>
  );
}
