class BankAccount {
  constructor(id, accountName, accountNumber, bankName, ifscCode, branchName) {
    this.id = id;
    this.accountName = accountName;
    this.accountNumber = accountNumber;
    this.bankName = bankName;
    this.ifscCode = ifscCode;
    this.branchName = branchName;
  }
}

export default BankAccount;
