import { TAX_RATES } from '../config/data/init';

export default class TaxRate {
  constructor(id, label, value) {
    this.id = id;
    this.label = label;
    this.value = value;
  }

  static withTax(label) {
    const taxSlab = TAX_RATES.filter((tax) => tax.label.toString() === label.toString());
    if (taxSlab.length > 0) return taxSlab[0];
    return new TaxRate(1, '0%', 0.0);
  }
}

// export default TaxRate;
