export const USERS_URL =
  'https://mobile-pos-e96ca-default-rtdb.asia-southeast1.firebasedatabase.app/users/';

export const apiService = (auth) => {
  const { userId } = auth;
  const idToken = auth.token;
  const BASE_URL = `${USERS_URL + userId}`;

  const addAuthParam = (url) => `${url}?auth=${idToken}`;

  const update = async (resourceUrl, data) =>
    fetch(addAuthParam(`${BASE_URL}/${resourceUrl}`), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

  const get = async (resourceUrl) =>
    fetch(addAuthParam(`${BASE_URL}/${resourceUrl}`), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

  return { update, get };
};
