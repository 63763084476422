import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const MeasurementUnit = () => {
  const TABLE_HEAD = [
    { id: 'id', label: 'Unit Id', alignRight: false },
    { id: 'label', label: 'Unit Label', alignRight: false },
    { id: 'value', label: 'Unit Value', alignRight: false },
    { id: '' }
  ];

  const { units } = useSelector((state) => state.settings.settings);

  return (
    <User title="Measurement Unit" tableHeader={TABLE_HEAD} data={units} type="MEASUREMENTUNIT" />
  );
};

export default MeasurementUnit;
