import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const Stock = () => {
  const TABLE_HEAD = [
    { id: 'title', label: 'Name', alignRight: false },
    { id: 'quantity', label: 'Quantity', alignRight: false },
    { id: 'unit', label: 'UOM', alignRight: false },
    { id: 'mrp', label: 'MRP', alignRight: false },
    { id: 'tax', label: 'Tax Rate', alignRight: false },
    { id: 'hsncode', label: 'HSN Code', alignRight: false },
    { id: '' }
  ];

  const { items } = useSelector((state) => state.stock.stock);

  return <User title="Stock" tableHeader={TABLE_HEAD} data={items} type="STOCK" />;
};

export default Stock;
