import PropTypes from 'prop-types';
// material
import { Grid, Typography } from '@material-ui/core';
import BankAccountCard from './BankAccountCard';

// ----------------------------------------------------------------------

BankAccountList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function BankAccountList({ products, ...other }) {
  return products.length === 0 ? (
    <Typography gutterBottom>
      Looks like there aren't any bank accounts. Add one by using the button above !!
    </Typography>
  ) : (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <BankAccountCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
}
