class PurchaseOrder {
  constructor(
    id,
    orderId,
    supplierId,
    supplierInvoiceId,
    items,
    subTotal,
    taxTotal,
    itemDiscountTotal,
    overallDiscountTotal,
    shippingCharges,
    roundOff,
    total,
    balance,
    paymentType,
    createdAt,
    updatedAt,
    paymentStatus,
    personThrough
  ) {
    this.id = id;
    this.orderId = orderId;
    this.supplierId = supplierId;
    this.supplierInvoiceId = supplierInvoiceId;
    this.items = items;
    this.subTotal = subTotal;
    this.taxTotal = taxTotal;
    this.itemDiscountTotal = itemDiscountTotal;
    this.overallDiscountTotal = overallDiscountTotal;
    this.shippingCharges = shippingCharges;
    this.roundOff = roundOff;
    this.total = total;
    this.balance = balance;
    this.paymentType = paymentType;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.paymentStatus = paymentStatus;
    this.personThrough = personThrough;
  }
}

export default PurchaseOrder;
