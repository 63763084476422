class Receipt {
  constructor(
    id,
    receiptId,
    customerId,
    invoiceIds,
    amount,
    paymentType,
    createdAt,
    updatedAt,
    bankAccount,
    notes
  ) {
    this.id = id;
    this.receiptId = receiptId;
    this.customerId = customerId;
    this.invoiceIds = invoiceIds;
    this.amount = amount;
    this.paymentType = paymentType;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.bankAccount = bankAccount;
    this.notes = notes;
  }
}

export default Receipt;
