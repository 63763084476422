import { toTwoDecimalPlaces } from '../utils/FloatUtils';
import { formatDate, sortItemsByDateWithField } from '../utils/DateUtils';

const renderItemRow = (item, index) => `
      <tr>
        <th>${index + 1}</th>
        <th>${formatDate(item.date)}</th>
        <th>${item.invoiceId}</th>
        <th>${item.customerName}</th>
        <th>${item.subTotal}</th>
        <th>${item.cgst}</th>
        <th>${item.sgst}</th>
        <th>${item.taxTotal}</th>
        <th>${item.total}</th>
      </tr>
    `;

const getDetails = (id, users) => users.find((user) => user.id.toString() === id.toString());

export const SalesReportTemplate = (
  type,
  fromDate,
  toDate,
  invoices,
  currencySymbol,
  firmDetails,
  users
) => {
  const isSalesReport = type === 'SALES';
  const title = isSalesReport ? 'Sales Report' : 'Purchase Orders Report';
  const summary = { paid: 0, due: 0 };

  const totals = {
    cgstTotal: 0.0,
    sgstTotal: 0.0,
    taxTotal: 0.0,
    subTotal: 0.0,
    amtTotal: 0.0
  };

  // console.log('Items:' + JSON.stringify(invoices));
  const invoiceRows = invoices.map((item) => {
    // console.log({ ...item });
    const invoiceItem = {};
    const invoiceId = isSalesReport ? item.invoiceId : item.orderId;
    invoiceItem.date = item.createdAt;
    invoiceItem.invoiceId = invoiceId;
    invoiceItem.customerName = isSalesReport
      ? getDetails(item.customerId, users).name
      : getDetails(item.supplierId, users).name;
    const taxTotal =
      parseFloat(item.total) -
      (parseFloat(item.subTotal) -
        parseFloat(item.itemDiscountTotal) +
        parseFloat(item.shippingCharges));
    invoiceItem.cgst = toTwoDecimalPlaces(taxTotal / 2);
    invoiceItem.sgst = toTwoDecimalPlaces(taxTotal / 2);
    invoiceItem.taxTotal = toTwoDecimalPlaces(taxTotal);
    invoiceItem.subTotal = toTwoDecimalPlaces(item.subTotal);
    invoiceItem.total = toTwoDecimalPlaces(item.total);

    totals.cgstTotal += parseFloat(invoiceItem.cgst);
    totals.sgstTotal += parseFloat(invoiceItem.sgst);
    totals.taxTotal += parseFloat(invoiceItem.taxTotal);
    totals.subTotal += parseFloat(invoiceItem.subTotal);
    totals.amtTotal += parseFloat(invoiceItem.subTotal) + parseFloat(invoiceItem.taxTotal);

    summary.paid += parseFloat(item.total) - parseFloat(item.balance);

    return invoiceItem;
  });

  summary.due = toTwoDecimalPlaces(parseFloat(totals.amtTotal) - parseFloat(summary.paid));
  summary.paid = toTwoDecimalPlaces(summary.paid);

  const rows = sortItemsByDateWithField([...invoiceRows], 'date');

  const tableRows = rows
    .map((row, index) => renderItemRow(row, index))
    .reduce((a, b) => `${a}\n${b}`, '');

  return `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8"/>
    <meta name="viewport" content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0, user-scalable=1"/>
    <title>${title}</title>
    <style>
      html {
        margin: auto;
        text-align: left;
      }

      .alnright { text-align: right; }
      .alnleft { text-align: left; }
      .alncenter { text-align: center; }

      p {
        margin: 5px;
      }

      table {
        width: 100%; padding: 5px;
      }
    </style>
</head>
<body>
    <div class="alncenter" id="title">
      <h2><u>${title}</u></h2>
    </div>
    <div id="header">
      <table>
        <tr colspan="12">
          <td colspan="6">
            <div>
              <p><strong><u>Firm Details:</u></strong></p>
              <p>${firmDetails.firmName}</p>
              <p>+91 ${firmDetails.phoneNumber}</p>
              <p>${firmDetails.firmAddress}</p>
              <p>${firmDetails.gstNumber}</p>
            </div>
          </td>
          <td colspan="6">
            <div>
              <p><strong>Summary:</strong></p>
              <table>
                <tr>
                  <td>Period: ${fromDate} - ${toDate}</td>
                </tr>
                <tr>
                  <td>Total Paid: <b>${currencySymbol.label} ${summary.paid}</b></td>
                </tr>
                <tr>
                  <td>Total Due: <b>${currencySymbol.label} ${summary.due}</b></td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <hr />
    <div id="summary-table">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Invoice #</th>
            <th>Customer Name</th>
            <th>Taxable Amt (${currencySymbol.label})</th>
            <th>CGST (${currencySymbol.label})</th>
            <th>SGST (${currencySymbol.label})</th>
            <th>Total Tax Amt (${currencySymbol.label})</th>
            <th>Total Amt (${currencySymbol.label})</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Totals</th>
            <th>${toTwoDecimalPlaces(totals.subTotal)}</th>
            <th>${toTwoDecimalPlaces(totals.cgstTotal)}</th>
            <th>${toTwoDecimalPlaces(totals.sgstTotal)}</th>
            <th>${toTwoDecimalPlaces(totals.taxTotal)}</th>
            <th>${toTwoDecimalPlaces(totals.amtTotal)}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </body>
</html>
    `;
};
