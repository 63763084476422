import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const PurchaseOrder = () => {
  const TABLE_HEAD = [
    { id: 'orderId', label: 'Order Id', alignRight: false },
    { id: 'createdAt', label: 'Date', alignRight: false },
    { id: 'supplierId', label: 'Supplier', alignRight: false },
    { id: 'total', label: 'Amount', alignRight: false },
    { id: 'balance', label: 'Balance', alignRight: false },
    { id: 'paymentStatus', label: 'Payment Status', alignRight: false },
    { id: '' }
  ];

  const { purchaseOrders } = useSelector((state) => state.purchaseOrders);
  const { suppliers } = useSelector((state) => state.suppliers);

  const getUserDetails = (id) =>
    suppliers.find((supplier) => supplier.id.toString() === id.toString());

  const updatedOrders = purchaseOrders.map((order) => ({
    ...order,
    supplierId: getUserDetails(order.supplierId).name
  }));

  return (
    <User
      title="Purchase Order"
      tableHeader={TABLE_HEAD}
      data={updatedOrders}
      type="PURCHASE_ORDER"
    />
  );
};

export default PurchaseOrder;
