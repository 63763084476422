import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Button, Container, Stack, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import cloudDownload from '@iconify/icons-eva/cloud-download-fill';
import printerOutline from '@iconify/icons-eva/printer-outline';
import { useReactToPrint } from 'react-to-print';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { receiptPreviewHtmlTemplate } from '../models/ReceiptTemplate';

const ReceiptPaymentPrintPreview = (props) => {
  const { id } = useParams();
  const { type } = props;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const isReceipt = type === 'RECEIPT';

  const { settings } = useSelector((state) => state.settings);

  const { customers } = useSelector((state) => state.customers);
  const { receipts } = useSelector((state) => state.receipts);

  const { suppliers } = useSelector((state) => state.suppliers);
  const { payments } = useSelector((state) => state.payments);

  const { invoices } = useSelector((state) => state.invoices);
  const { purchaseOrders } = useSelector((state) => state.purchaseOrders);

  const getUserDetails = (receiptDetails) =>
    isReceipt
      ? getCustomerDetails(receiptDetails.customerId)
      : getSupplierDetails(receiptDetails.supplierId);

  const getCustomerDetails = (id) =>
    customers.find((customer) => customer.id.toString() === id.toString());

  const getSupplierDetails = (id) =>
    suppliers.find((supplier) => supplier.id.toString() === id.toString());

  const getReceiptPaymentDetails = (id) =>
    isReceipt
      ? receipts.find((receipt) => receipt.id.toString() === id.toString())
      : payments.find((payment) => payment.id.toString() === id.toString());

  const populateInvoiceDetails = (receiptPaymentRecord) => {
    if (isReceipt) {
      const { invoiceIds } = receiptPaymentRecord;
      const invRecords = invoiceIds; // invoices.filter((invoice) => invoiceIds.includes(invoice.invoiceId));
      return {
        ...receiptPaymentRecord,
        invoices: invRecords
      };
    }
    const { purchaseOrderIds } = receiptPaymentRecord;
    const purchaseRecords = purchaseOrderIds; // purchaseOrders.filter((purchaseOrder) => purchaseOrderIds.includes(purchaseOrder.orderId));
    return {
      ...receiptPaymentRecord,
      purchaseOrders: purchaseRecords
    };
  };

  const receiptDetails = populateInvoiceDetails(getReceiptPaymentDetails(id));

  const firmDetails = settings.user.companyProfile;

  const printData = {
    type,
    firmDetails,
    customerDetails: getUserDetails(receiptDetails),
    receiptDetails,
    billingSettings: settings.billing,
    printSettings: settings.printing,
    taxSettings: settings.taxSlabs
  };

  // console.log(printData);

  const printPreview = receiptPreviewHtmlTemplate(
    printData.type,
    1,
    printData.firmDetails,
    printData.customerDetails,
    printData.receiptDetails,
    printData.billingSettings
  );

  const printReceipt = () => {
    const b = window.open(' ');
    b.document.write(printPreview);
    b.document.close();
    b.print();
  };

  return (
    <Page title="Dashboard: Print Preview | Quick Bill">
      <Scrollbar>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {isReceipt ? 'Receipt ' : 'Payment '} Preview
            </Typography>
            <Stack direction="row" width="40%" alignItems="right" justifyContent="space-around">
              <Button
                variant="contained"
                onClick={handlePrint}
                startIcon={<Icon icon={cloudDownload} />}
              >
                Print {isReceipt ? 'Receipt ' : 'Payment'} (PDF)
              </Button>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            margin={5}
            ref={componentRef}
          >
            {parse(printPreview)}
          </Stack>
        </Container>
      </Scrollbar>
    </Page>
  );
};

export default ReceiptPaymentPrintPreview;
