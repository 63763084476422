import React from 'react';
import { useSelector } from 'react-redux';
import User from './User';

const Invoice = () => {
  const TABLE_HEAD = [
    { id: 'invoiceId', label: 'Invoice Id', alignRight: false },
    { id: 'createdAt', label: 'Date', alignRight: false },
    { id: 'customerId', label: 'Customer', alignRight: false },
    { id: 'total', label: 'Amount', alignRight: false },
    { id: 'balance', label: 'Balance', alignRight: false },
    { id: 'paymentStatus', label: 'Payment Status', alignRight: false },
    { id: '' }
  ];

  const { invoices } = useSelector((state) => state.invoices);
  const { customers } = useSelector((state) => state.customers);

  const getUserDetails = (id) =>
    customers.find((customer) => customer.id.toString() === id.toString());

  const updatedInvoices = invoices.map((invoice) => ({
    ...invoice,
    customerId: getUserDetails(invoice.customerId).name
  }));

  return <User title="Invoice" tableHeader={TABLE_HEAD} data={updatedInvoices} type="INVOICE" />;
};

export default Invoice;
